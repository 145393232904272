import React from 'react'
import { LinkProps as RouterLinkProps } from 'react-router-dom'
import { ArrowThinRight } from '@revolut/icons'
import { Flex } from '@revolut/ui-kit'
import styled from 'styled-components'
import type { ResponsiveStyleValue } from '@styled-system/css'
import { css } from '@styled-system/css'
import type { Property } from 'csstype'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { Text } from './Text'

export interface LinkArrowRoundProps extends RouterLinkProps {
  useIcon?: React.ComponentType<{ size?: number }> | false
  variant?: 'horizontal' | 'vertical'
  arrowVariant?: 'accent' | 'default'
  title?: string
}

const LinkArrowRoundIconBase = styled(Flex)<{
  arrowVariant: 'accent' | 'default'
}>(({ alignSelf = 'end', marginLeft, arrowVariant }) =>
  css({
    alignItems: 'center',
    alignSelf,
    borderRadius: 9999,
    color: arrowVariant === 'default' ? 'foreground' : 'accent',
    flexShrink: 0,
    height: 32,
    justifyContent: 'center',
    justifySelf: 'end',
    marginLeft,
    transition: 'all 0.3s',
    width: 32,
  }),
)

const LinkArrowRoundBase = styled(InternalLink)<{
  flexDirection?: ResponsiveStyleValue<Property.FlexDirection>
  arrowVariant: 'accent' | 'default'
}>(({ flexDirection, arrowVariant }) =>
  css({
    display: 'flex',
    flexDirection,

    [`&:hover ${LinkArrowRoundIconBase}`]: {
      backgroundColor: arrowVariant === 'default' ? 'foreground' : 'accent',
      color: arrowVariant === 'default' ? 'background' : 'white',
    },
  }),
)

const ArrowIcon = styled(ArrowThinRight)({
  marginRight: '1px',
})

export const LinkArrowRound = ({
  children,
  useIcon: Icon,
  variant = 'vertical',
  arrowVariant = 'default',
  title,
  ...rest
}: LinkArrowRoundProps) => {
  return (
    <LinkArrowRoundBase
      flexDirection={variant === 'horizontal' ? 'row' : 'column'}
      arrowVariant={arrowVariant}
      title={title}
      {...rest}
    >
      {children}
      <Flex justifyContent="center" alignItems="center" gap="s-8" color={arrowVariant}>
        {title && (
          <Text
            // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
            variant="subtitle2"
          >
            {title}
          </Text>
        )}
        <LinkArrowRoundIconBase
          use="span"
          alignSelf={variant === 'horizontal' ? 'center' : 'end'}
          ml={title ? 'initial' : 'auto'}
          arrowVariant={arrowVariant}
        >
          {Icon ? <Icon /> : <ArrowIcon />}
        </LinkArrowRoundIconBase>
      </Flex>
    </LinkArrowRoundBase>
  )
}
