import React, { useState } from 'react'
import capitalize from 'lodash/capitalize'
import { Group, MoreBar, Text, TextButton, VStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { sendSignatureRequest } from '@src/api/onboardingEmployeesV2'
import {
  approveDocumentUpload,
  deleteDocumentUploadFile,
  rejectDocumentUpload,
  useGetDocusignInfo,
  useGetUploadedDocument,
} from '@src/api/documents'
import {
  DocumentUploadRequestInterface,
  DocusignDocumentInterface,
} from '@src/interfaces/documents'
import { OnboardingTemplateInterface } from '@src/interfaces/onboardingV2'
import { getDocumentStatusColor } from '@src/constants/columns/documents'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import { getStatusColor } from '@components/CommonSC/General'
import { FormPreview } from '@components/FormPreview/FormPreview'
import SideBar from '@components/SideBar/SideBar'
import SettingsButtons from '@src/features/SettingsButtons'
import { AttachedDocument } from '@src/features/DocumentSidebar/DocumentSidebar'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { getTemplateStatusColor } from '../utils'

type Props = {
  document?: OnboardingTemplateInterface
  employeeId: string
  onboardingId: string
  onClose: () => void
}

export const PreviewTemplateSidebar = ({
  document,
  employeeId,
  onboardingId,
  onClose,
}: Props) => {
  const [isApprovalPending, setIsApprovalPending] = useState(false)
  const [isDeletePending, setIsDeletePending] = useState(false)
  const [isRejectPending, setIsRejectPending] = useState(false)
  const [isSendSignaturePending, setIsSendSignaturePending] = useState(false)

  const {
    data: docusignDocument,
    isLoading: isLoadingDocusignDocument,
    refetch: refetchDocusignDocument,
  } = useGetDocusignInfo(
    document?.document_template.template_type.id === 'esignature'
      ? document.uploaded_document?.id
      : undefined,
  )
  const {
    data: uploadedDocument,
    isLoading: isLoadingUploadedDocument,
    refetch: refetchUploadedDocument,
  } = useGetUploadedDocument(
    Number(employeeId),
    document?.document_template.template_type.id === 'request'
      ? document.uploaded_document?.id
      : undefined,
  )

  const isPendingApproval = uploadedDocument?.status?.id === 'pending_approval'

  const renderSidebarContent = () => {
    if (document?.document_template.template_type.id === 'esignature') {
      if (!docusignDocument) {
        return (
          <VStack gap="s-16">
            <MoreBar>
              <MoreBar.Action
                onClick={async () => {
                  try {
                    setIsSendSignaturePending(true)
                    await sendSignatureRequest(onboardingId, document.id)
                    refetchDocusignDocument()
                  } finally {
                    setIsSendSignaturePending(false)
                  }
                }}
                pending={isSendSignaturePending}
                useIcon="Pencil"
                variant="accent"
              >
                Send for signature
              </MoreBar.Action>
            </MoreBar>
            <FormPreview data={document}>
              <Group>
                <FormPreview.Item<OnboardingTemplateInterface>
                  field="document_template.category.name"
                  title="Category"
                  insert={d => {
                    return (
                      <TextButton
                        onClick={() => {
                          onClose()
                          navigateTo(
                            pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                              employeeId,
                              categoryId: d.document_template.category?.id,
                            }),
                          )
                        }}
                      >
                        {d.document_template.category?.name}
                      </TextButton>
                    )
                  }}
                />
                <FormPreview.Item<OnboardingTemplateInterface>
                  color={() => getTemplateStatusColor(document.status.id)}
                  field="status.name"
                  title="Status"
                />
                <FormPreview.Item<OnboardingTemplateInterface>
                  field="created_by.name"
                  title="Issued by"
                  to={d =>
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                      id: d.created_by.id,
                    })
                  }
                />
                <FormPreview.Item
                  field="creation_date_time"
                  title="Created on"
                  type="date"
                />
                <FormPreview.Item
                  field="update_date_time"
                  title="Updated on"
                  type="date"
                />
              </Group>
            </FormPreview>
          </VStack>
        )
      }

      if (isLoadingDocusignDocument) {
        return <Loader />
      }

      return (
        <FormPreview data={docusignDocument}>
          <Group>
            <FormPreview.Item<DocumentUploadRequestInterface>
              field="category.name"
              title="Category"
              insert={d => {
                return (
                  <TextButton
                    onClick={() => {
                      onClose()
                      navigateTo(
                        pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                          employeeId,
                          categoryId: d.category?.id,
                        }),
                      )
                    }}
                  >
                    {d.category?.name}
                  </TextButton>
                )
              }}
            />
            <FormPreview.Item<DocusignDocumentInterface>
              field="status"
              title="Status"
              insert={d =>
                d.status ? (
                  <Text color={getStatusColor(d.status)}>{capitalize(d.status)}</Text>
                ) : (
                  '-'
                )
              }
            />
            <FormPreview.Item<DocusignDocumentInterface>
              field="sender.name"
              title="Issued by"
              to={d =>
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: d.sender.id,
                })
              }
            />
            <FormPreview.Item field="sent_date_time" title="Sent date" type="date" />
            <FormPreview.Item<DocusignDocumentInterface>
              field="signed_date_time"
              insert={d => {
                if (d.signed_date_time) {
                  return formatDate(d.signed_date_time)
                }
                return 'Not signed yet'
              }}
              title="Signed on"
            />
          </Group>
        </FormPreview>
      )
    }
    if (document?.document_template.template_type.id === 'request') {
      return !uploadedDocument || isLoadingUploadedDocument ? (
        <Loader />
      ) : (
        <AttachedDocument
          id={Number(document.uploaded_document?.id)}
          document={uploadedDocument}
          renderActions={() => (
            <SettingsButtons>
              {isPendingApproval && (
                <>
                  <MoreBar.Action
                    onClick={async () => {
                      try {
                        setIsApprovalPending(true)
                        await approveDocumentUpload(employeeId, uploadedDocument?.id!)
                        refetchUploadedDocument()
                      } finally {
                        setIsApprovalPending(false)
                      }
                    }}
                    pending={isApprovalPending}
                    useIcon="Check"
                  >
                    Approve
                  </MoreBar.Action>
                  <MoreBar.Action
                    onClick={async () => {
                      try {
                        setIsRejectPending(true)
                        await rejectDocumentUpload(employeeId, uploadedDocument?.id!)
                        refetchUploadedDocument()
                      } finally {
                        setIsRejectPending(false)
                      }
                    }}
                    pending={isRejectPending}
                    useIcon="Cross"
                    variant="negative"
                  >
                    Reject
                  </MoreBar.Action>
                  <MoreBar.Action
                    onClick={async () => {
                      try {
                        setIsDeletePending(true)
                        await deleteDocumentUploadFile(uploadedDocument?.id!)
                        refetchUploadedDocument()
                      } finally {
                        setIsDeletePending(false)
                      }
                    }}
                    pending={isDeletePending}
                    useIcon="Delete"
                    variant="negative"
                  >
                    Delete document
                  </MoreBar.Action>
                </>
              )}
            </SettingsButtons>
          )}
          renderAbovePreview={() => (
            <FormPreview data={uploadedDocument}>
              <Group>
                <FormPreview.Item<DocumentUploadRequestInterface>
                  field="category.name"
                  title="Category"
                  insert={d => {
                    return (
                      <TextButton
                        onClick={() => {
                          onClose()
                          navigateTo(
                            pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                              employeeId,
                              categoryId: d.category?.id,
                            }),
                          )
                        }}
                      >
                        {d.category?.name}
                      </TextButton>
                    )
                  }}
                />
                <FormPreview.Item
                  color={() => getDocumentStatusColor(uploadedDocument.status.id)}
                  field="status.name"
                  title="Status"
                />
                <FormPreview.Item<DocumentUploadRequestInterface>
                  field="issuer.name"
                  to={d =>
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                      id: d.issuer.id,
                    })
                  }
                  title="Issued by"
                />
                <FormPreview.Item
                  field="creation_date_time"
                  title="Created on"
                  type="date"
                />
                <FormPreview.Item
                  field="updated_date_time"
                  title="Updated on"
                  type="date"
                />
              </Group>
            </FormPreview>
          )}
        />
      )
    }
    return null
  }

  return (
    <SideBar
      isOpen={!!document}
      onClose={onClose}
      sideProps={{ resizable: true }}
      title={document?.document_template.name}
    >
      {renderSidebarContent()}
    </SideBar>
  )
}
