import React from 'react'
import { MoreBar, TableWidget } from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { InternalLink } from '@components/InternalLink/InternalLink'

export const WorkScheduleList = () => {
  return (
    <PageWrapper>
      <PageHeader title="Work Schedules" backUrl={ROUTES.SETTINGS.TIME_OFF.GENERAL} />
      <PageBody>
        <TableWidget>
          <TableWidget.Actions>
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                variant="accent"
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.EDIT.BASICS)}
              >
                Create new schedule
              </MoreBar.Action>
            </MoreBar>
          </TableWidget.Actions>
        </TableWidget>
      </PageBody>
    </PageWrapper>
  )
}
