import React from 'react'
import { Text, Flex, Color } from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'

import Tooltip from '@src/components/Tooltip/Tooltip'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { ImportDepartmentBudgetInterface } from '@src/interfaces/importDepartmentBudget'
import {
  CurrentSalaryCell,
  SalaryBandCell,
  SeniorityCell,
  SpecialisationCell,
  getChangeColor,
  SalaryChangeCell,
  getSalaryChangePercentValue,
  BonusChangeCell,
} from './compensation'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { formatMoneyWithCode } from '@src/utils/format'

interface CellWithErrorProps {
  data: ImportDepartmentBudgetInterface
  prop: keyof Omit<ImportDepartmentBudgetInterface, 'errors'>
}

const CellWithError: React.FC<CellWithErrorProps> = ({ prop, data, children }) => {
  const errors = data.errors?.[prop]
  const fallbackValue = data[prop]
  const hasError = !!errors?.length

  return (
    <Flex>
      <Tooltip text={errors?.join('\n')} placement="top" hide={!hasError} noArrow>
        <Flex gap="s-8" justifyContent="flex-start">
          <Text color={hasError ? Color.RED : Color.FOREGROUND}>
            {children || fallbackValue}
          </Text>
          {hasError ? <ExclamationMarkOutline color={Color.RED} size={16} /> : null}
        </Flex>
      </Tooltip>
    </Flex>
  )
}

export const importDepartmentBudgetEmployeeColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee',
    dataPoint: 'employee',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
    insert: ({ data }) => (
      <CellWithError data={data} prop="employee">
        {data.employee_id && data.employee_full_name ? (
          <UserWithAvatar
            id={data.employee_id}
            name={data.employee_full_name}
            avatar={data.employee_avatar}
          />
        ) : null}
      </CellWithError>
    ),
  }

export const importDepartmentBudgetSeniorityColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Seniority',
    insert: ({ data }) => {
      if (!data.seniority_name || !data.employee_id) {
        return '-'
      }

      return (
        <SeniorityCell
          employeeId={data.employee_id}
          seniorityName={data.seniority_name}
          specialisationSeniorityName={data.specialisation_seniority_sublevel_name}
        />
      )
    },
  }

export const importDepartmentBudgetRoleColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Role (specialisation)',
    insert: ({ data }) => {
      if (!data.employee_id) {
        return '-'
      }

      return (
        <SpecialisationCell
          employeeId={data.employee_id}
          specialisationName={data.specialisation_name}
        />
      )
    },
  }

export const importDepartmentBudgetLocationColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.text,
    idPoint: 'location_name',
    dataPoint: 'location_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Location',
  }

export const importDepartmentBudgetNewSalaryColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'salary_amount_new',
    dataPoint: 'salary_amount_new',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'New salary',
    insert: ({ data }) => {
      return (
        <CellWithError data={data} prop="salary_amount_new">
          {data.salary_amount_new != null && data.salary_currency_iso_code != null
            ? formatMoneyWithCode(data.salary_amount_new, data.salary_currency_iso_code)
            : null}
        </CellWithError>
      )
    },
  }

export const importDepartmentBudgetNewBonusColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'bonus_amount_new',
    dataPoint: 'bonus_amount_new',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Bonus grant',
    insert: ({ data }) => {
      return (
        <CellWithError data={data} prop="bonus_amount_new">
          {data.bonus_amount_new != null && data.bonus_currency_iso_code != null
            ? formatMoneyWithCode(data.bonus_amount_new, data.bonus_currency_iso_code)
            : null}
        </CellWithError>
      )
    },
  }

export const importDepartmentBudgetCurrentSalaryColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Current salary',
    insert: ({ data }) => {
      return (
        <CurrentSalaryCell
          employeeId={data.employee_id}
          salaryAmount={data.salary_amount}
          currencyCode={data.salary_currency_iso_code}
        />
      )
    },
  }

export const importDepartmentBudgetSalaryBandColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary band',
    insert: ({ data }) => {
      return (
        <SalaryBandCell
          salaryAmount={data.salary_amount}
          upperBand={data.salary_benchmark_upper_band}
          lowerBand={data.salary_benchmark_lower_band}
          currencyCode={data.salary_benchmark_currency_iso_code}
        />
      )
    },
  }

export const importDepartmentBudgetSalaryChangeColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary change',
    colors: data => getChangeColor(data.salary_change),
    insert: ({ data }) => {
      return (
        <SalaryChangeCell
          salaryChange={data.salary_change}
          currencyCode={data.salary_currency_iso_code}
        />
      )
    },
  }

export const importDepartmentBudgetSalaryChangePercentColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary change (in %)',
    colors: data => getChangeColor(data.salary_change_percent),
    insert: ({ data }) =>
      getSalaryChangePercentValue(data.salary_amount, data.salary_change_percent),
  }

export const importDepartmentBudgetBonusChangeColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Bonus change',
    insert: ({ data }) => {
      return (
        <BonusChangeCell
          bonusChange={data.bonus_change}
          currencyCode={data.bonus_currency_iso_code}
          employeeId={data.employee_id}
        />
      )
    },
  }

export const importDepartmentBudgetJustificationColumn: ColumnInterface<ImportDepartmentBudgetInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Justification',
    insert: ({ data }) => {
      return (
        <CellWithError data={data} prop="justification">
          {data.justification || '-'}
        </CellWithError>
      )
    },
  }
