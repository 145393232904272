import React, { useRef, useState } from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import { Button, Side, TabBar } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { useTable } from '@src/components/Table/hooks'
import { hiringProcessesStagesRequests } from '@src/api/hiringProcess'
import { useGetSpecialisation } from '@src/api/specialisations'
import { useGetRole } from '@src/api/roles'
import { CompanyHiringStages } from '@src/components/HiringStagesBank/CompanyHiringStages'
import { RoleHiringStages } from '@src/components/HiringStagesBank/RoleHiringStages'

export type OnConfirmArguments = {
  companyStages: HiringProcessInterface[]
  roleStages: HiringProcessInterface[]
}

type HiringStagesBankSidebarProps = {
  confirmLabel: string
  hasCVScreening: boolean
  disableCVScreening: boolean
  includeRoles: boolean
  loading: boolean
  pending: boolean
  singleSelect: boolean
  specialisationId?: string | number
  onConfirm: ({ companyStages, roleStages }: OnConfirmArguments) => void
  onClose: () => void
}

type HiringStagesBankTab = 'company' | 'role'

export const HiringStagesBankSidebar = ({
  confirmLabel,
  disableCVScreening,
  hasCVScreening,
  includeRoles,
  loading,
  pending,
  singleSelect,
  specialisationId,
  onConfirm,
  onClose,
}: HiringStagesBankSidebarProps) => {
  const [tab, setTab] = useState<HiringStagesBankTab>('company')
  const table = useTable<HiringProcessInterface>(hiringProcessesStagesRequests)
  const [companySelectedStages, setCompanySelectedStages] = useState<
    HiringProcessInterface[]
  >([])
  const scrollRef = useRef<HTMLDivElement>(null)
  const { data: specialisation, isLoading: loadingSpecialisation } =
    useGetSpecialisation(specialisationId)
  const { data: role, isLoading: loadingRole } = useGetRole(specialisation?.role?.id)
  const [roleSelectedStages, setRoleSelectedStages] = useState<HiringProcessInterface[]>(
    [],
  )
  const hasCVScreeningSelected = [...companySelectedStages, ...roleSelectedStages].some(
    stage => stage.stage_type.id === 'cv_screening',
  )
  return (
    <SideBar
      isOpen
      variant="wide"
      title="Hiring stages Bank"
      onClose={onClose}
      sideProps={{ scrollRef }}
    >
      <TabBar
        variant="segmented"
        value={tab}
        onChange={newTab => {
          if (newTab) {
            setTab(newTab)
          }
        }}
      >
        <TabBar.Item to="company">Company</TabBar.Item>
        {includeRoles && <TabBar.Item to="role">Role</TabBar.Item>}
      </TabBar>
      {tab === 'company' && (
        <CompanyHiringStages
          disabled={loading}
          disableSelectingCVScreeningStages={
            disableCVScreening ? hasCVScreening || hasCVScreeningSelected : false
          }
          scrollRef={scrollRef}
          selected={companySelectedStages}
          table={table}
          onFetchNextPage={table.fetchNextPage}
          onChangeSelected={selectedStages => {
            const last = selectedStages.at(-1)
            if (singleSelect && last) {
              setCompanySelectedStages([last])
              setRoleSelectedStages([])
            } else {
              setCompanySelectedStages(selectedStages)
            }
          }}
        />
      )}
      {tab === 'role' && (
        <RoleHiringStages
          hiringStages={role?.hiring_process_rounds}
          disabled={loading}
          disableSelectingCVScreeningStages={
            disableCVScreening ? hasCVScreening || hasCVScreeningSelected : false
          }
          loadingHiringStages={loadingSpecialisation || loadingRole}
          selected={roleSelectedStages}
          specialisationName={specialisation?.name}
          onChangeSelected={selectedStages => {
            const last = selectedStages.at(-1)
            if (singleSelect && last) {
              setCompanySelectedStages([])
              setRoleSelectedStages([last])
            } else {
              setRoleSelectedStages(selectedStages)
            }
          }}
        />
      )}
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm({
              companyStages: companySelectedStages,
              roleStages: roleSelectedStages,
            })
          }}
          pending={loading || pending}
          disabled={!companySelectedStages.length && !roleSelectedStages.length}
        >
          {confirmLabel}
        </Button>
      </Side.Actions>
    </SideBar>
  )
}
