import React, { useMemo, useState } from 'react'
import { BREAKPOINTS, MoreBar, Token } from '@revolut/ui-kit'

import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { getEditableTable } from '@src/features/GenericEditableTable/api'
import { CreateTeamPopup } from '@src/features/GenericEditableTable/CellPopups/CreateTeam'
import { CreateDepartmentPopup } from '@src/features/GenericEditableTable/CellPopups/CreateDepartment'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  ImportInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { TeamInterface } from '@src/interfaces/teams'
import {
  onboardingTeamsDepartmentColumn,
  onboardingTeamsHeadcountColumn,
  onboardingTeamsNameColumn,
  onboardingTeamsOwnerColumn,
} from '@src/constants/columns/onboardingTeams'
import { API } from '@src/constants/api'
import { GetRequestData, IdAndName } from '@src/interfaces'
import {
  BulkUpdateTeamPopup,
  DeleteTeamsConfirmationPopup,
  MergeTeamsPopup,
} from './components'
import { useOnboardingContext } from '@src/pages/OnboardingChecklistV2/components/OnboardingContext'
import { useTable } from '@src/components/Table/hooks'
import { StatFilters } from '@src/components/StatFilters/StatFilters'
import { useGetTeamsStats } from './hooks'
import OnboardingActions from '../components/OnboardingActions'
import { teamsConfig } from '../common/checkpointsConfig'
import { PageBody } from '@src/components/Page/PageBody'

type CreateCallback = (onChangeAction: (entity: IdAndName) => void) => void

const row =
  (onCreate: CreateCallback) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<TeamInterface>> => ({
    cells: [
      {
        ...onboardingTeamsNameColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingTeamsDepartmentColumn(options.onChange, onChangeAction =>
          onCreate(onChangeAction),
        ),
        width: 200,
      },
      {
        ...onboardingTeamsHeadcountColumn(options.onChange),
        width: 100,
      },
      {
        ...onboardingTeamsOwnerColumn(options.onChange),
        width: 200,
      },
    ],
  })

type CreateNewDepartmentPopupState = {
  type: 'create-department'
  onChangeAction: (entity: IdAndName) => void
}
type AddTeamPopupState = {
  type: 'add-team'
  refreshTable: () => void
}
type BulkUpdateFieldPopupState = {
  type: 'bulk-update-field'
  field?: 'department' | 'owner'
  items?: number[]
  refreshTable: () => void
}
type BulkDeletePopupState = {
  type: 'bulk-delete'
  items?: number[]
  refreshTable: () => void
}
type MergeTeamsPopupState = {
  type: 'merge-teams'
  items?: number[]
  refreshTable: () => void
}
type PopupState =
  | null
  | AddTeamPopupState
  | BulkUpdateFieldPopupState
  | BulkDeletePopupState
  | MergeTeamsPopupState

const initialFilter = [
  {
    columnName: 'status',
    filters: [{ id: 'active', name: 'active' }],
    nonResettable: true,
  },
]

export const ReviewTeams = () => {
  const { setNextButtonState } = useOnboardingContext()
  const [popupState, setPopupState] = useState<PopupState>(null)
  const [createDepartmentPopupState, setCreateDepartmentPopupState] =
    useState<null | CreateNewDepartmentPopupState>(null)

  const table = useTable<ImportInterface<TeamInterface>, ImportSessionStatsInterface>(
    {
      getItems: getEditableTable<TeamInterface>(
        API.TEAMS,
        undefined,
        (data: GetRequestData<TeamInterface>) => ({
          ...data,
          results: data.results.map(r => ({
            id: r.id,
            data: r,
            errors:
              r.team_owner == null ? { team_owner: ['Team owner is required'] } : {},
            state: { id: 'pending' as const, name: 'Pending' },
            error_message: null,
            loading: {},
          })),
        }),
      ),
    },
    initialFilter,
    [{ sortBy: 'name', nonResettable: true }],
  )

  const teamStats = useGetTeamsStats(initialFilter)

  const filters = useMemo(() => {
    return [
      {
        id: 'total' as const,
        title: 'Total',
        value: teamStats.total,
        loading: teamStats.isLoading,
      },
      teamStats.errors && teamStats.errors > 0
        ? {
            id: 'errors' as const,
            title: 'Errors',
            value: teamStats.errors,
            loading: teamStats.isLoading,
            color: Token.color.error,
          }
        : null,
    ].filter(Boolean)
  }, [teamStats.errors, teamStats.total, teamStats.isLoading])

  const onCreateNew = (onChangeAction: (entity: IdAndName) => void) => {
    setCreateDepartmentPopupState({ type: 'create-department', onChangeAction })
  }

  const onErrorFilterChange = (filter: 'total' | 'errors') => {
    if (filter === 'total') {
      table.resetFiltersAndSorting()
      return
    }

    table.onFilterChange([
      {
        columnName: 'id',
        filters: teamStats.errorRows?.map(r => ({ id: r.id, name: '' })) || [],
      },
    ])
  }

  return (
    <>
      <PageBody maxWidthMd={BREAKPOINTS.lg}>
        <GenericEditableTable
          table={table}
          apiEndpoint={API.TEAMS}
          tableName={TableNames.TeamsOnboarding}
          row={row(onCreateNew)}
          entity="team"
          variant="existingEntities"
          onChangeValidationState={state => {
            if (teamStats.isLoading || teamStats.errors == null || teamStats.errors > 0) {
              return
            }

            if (state === 'valid') {
              setNextButtonState('active')
            } else if (state === 'invalid') {
              setNextButtonState('disabled')
            }
          }}
          tableInfo={
            <StatFilters
              filters={filters}
              onClick={onErrorFilterChange}
              selectedFilter="none"
            />
          }
          onAfterRefresh={() => teamStats.refetch()}
          tableActions={props => {
            const selectedItems = props.getSelectedItems()
            const disableActions = selectedItems?.length < 1
            const disableMerge = selectedItems?.length < 2

            const refreshTable = () => {
              props.refreshTableState()
            }

            return (
              <MoreBar>
                <MoreBar.Action
                  useIcon="Plus"
                  onClick={() => {
                    setPopupState({
                      type: 'add-team',
                      refreshTable,
                    })
                  }}
                >
                  Add team
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="ArrowRightLeft"
                  onClick={() => {
                    setPopupState({
                      type: 'bulk-update-field',
                      field: 'department',
                      items: props.getSelectedItems(),
                      refreshTable,
                    })
                  }}
                  disabled={disableActions}
                >
                  Change department
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="Materials"
                  onClick={() => {
                    setPopupState({
                      type: 'merge-teams',
                      items: props.getSelectedItems(),
                      refreshTable,
                    })
                  }}
                  disabled={disableMerge}
                >
                  Merge teams
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="ArrowRightLeft"
                  onClick={() => {
                    setPopupState({
                      type: 'bulk-update-field',
                      field: 'owner',
                      items: props.getSelectedItems(),
                      refreshTable,
                    })
                  }}
                  disabled={disableActions}
                >
                  Change owner
                </MoreBar.Action>
                <MoreBar.Action
                  onClick={() => {
                    setPopupState({
                      type: 'bulk-delete',
                      items: props.getSelectedItems(),
                      refreshTable,
                    })
                  }}
                  variant="negative"
                  useIcon="Delete"
                  disabled={disableActions}
                >
                  Delete
                </MoreBar.Action>
              </MoreBar>
            )
          }}
          deleteConfirmation={props => {
            return props.data?.id ? (
              <DeleteTeamsConfirmationPopup
                open={props.open}
                onClose={props.onClose}
                teamsToDelete={[props.data.id]}
                onSuccess={props?.onSuccess}
              />
            ) : null
          }}
        />
      </PageBody>

      {popupState?.type === 'add-team' && (
        <CreateTeamPopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
          showDepartmentField
        />
      )}

      {createDepartmentPopupState?.type === 'create-department' && (
        <CreateDepartmentPopup
          open
          onSuccess={department => {
            createDepartmentPopupState.onChangeAction(department)
            setCreateDepartmentPopupState(null)
          }}
          onClose={() => setCreateDepartmentPopupState(null)}
        />
      )}

      {popupState?.type === 'merge-teams' && (
        <MergeTeamsPopup
          open
          onClose={() => setPopupState(null)}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          unitsToMerge={popupState.items}
        />
      )}

      {popupState?.type === 'bulk-update-field' && (
        <BulkUpdateTeamPopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
          entity={popupState.field}
          selectedTeams={popupState.items}
          onCreateNew={onChangeAction => {
            setCreateDepartmentPopupState({ type: 'create-department', onChangeAction })
          }}
        />
      )}

      {popupState?.type === 'bulk-delete' && (
        <DeleteTeamsConfirmationPopup
          open
          onClose={() => setPopupState(null)}
          teamsToDelete={popupState.items}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
        />
      )}

      <OnboardingActions
        currentStep="Review teams"
        config={teamsConfig}
        isLastStep
        disableNext={
          teamStats.isLoading || teamStats.errors == null || teamStats.errors > 0
        }
        pendingNext={false}
        updateSteps
        nextRoute=""
        isForm={false}
      />
    </>
  )
}
