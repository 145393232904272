import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import React, { useState } from 'react'
import { Spinner } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Statuses } from '@src/interfaces'
import { captureException } from '@sentry/core'
import { getDefaultBackRoute, useSubmitFlowHelpers } from './common/utils'
import { deleteGoal } from '@src/api/goals'
import { goBack, navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { FormObserverProvider } from './Form/Widgets/FormObserverProvider'
import { useGoalFormSubmit } from './Form/useGoalFormSubmit'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { GoalFormPageBody } from './GoalFormPageBody'
import { useGoalFormCache } from './useGoalFormCache'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'

export const NewGoalFormPage = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: reviewCycles } = useGetReviewCycles()
  const { cleanCache } = useGoalFormCache()
  const [autoDeletePending, setAutoDeletePending] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const { query } = useQuery()

  const { confirm, prompt, confirmationDialog } = useSubmitFlowHelpers()
  const { submit } = useGoalFormSubmit()
  const isOnboarding = isOnboardingPath()

  const isDraft = values.approval_status.id === ApprovalStatuses.Draft

  const shouldAutoDelete = isDraft
  const shouldConfirmBack = isDraft && isDirty

  const backUrl = getDefaultBackRoute(values, isOnboarding)

  const getContentLabelByType = () => {
    switch (query.type) {
      case EntityTypes.teams:
      case EntityTypes.team:
        return 'team'
      case EntityTypes.department:
        return 'department'
      case EntityTypes.employees:
      case EntityTypes.employee:
        return 'employee'
      case EntityTypes.function:
        return 'function'
      case EntityTypes.role:
        return 'role'
      case EntityTypes.specialisation:
        return 'specialisation'
      case EntityTypes.company:
      case EntityTypes.companyV2:
      default:
        return 'company'
    }
  }

  const isDefaultCycle = (cycle: ReviewCyclesInterface) => {
    return cycle.offset === 0
  }

  const entityTitle = getContentLabelByType()

  const title = `Add ${entityTitle} goal`

  const deleteAndGoBack = async () => {
    setAutoDeletePending(true)
    await deleteGoal(values.id).catch(captureException)
    cleanCache()
    setAutoDeletePending(false)
    goBack(backUrl)
  }

  const confirmBack = async () => {
    const confirmVariant = values.name ? confirm : prompt

    const confirmed = await confirmVariant({
      yesMessage: 'Save as draft',
      noMessage: 'Delete goal',
      noBtnVariant: 'negative',
      variant: 'compact',
      label: 'You have unsaved changes',
      body: 'Do you want to save them or delete before proceeding?',
      promptLabel: 'Please set goal name before saving',
      commentRequired: true,
    })

    if (confirmed.status === 'canceled') {
      return
    }

    if (confirmed.status === 'confirmed') {
      if (confirmed.comment) {
        values.name = confirmed.comment
      }
      await submit(false)
      goBack(backUrl)
    }

    if (confirmed.status === 'rejected') {
      deleteAndGoBack()
    }
  }

  const handleBack = () => {
    if (shouldConfirmBack) {
      confirmBack()
    } else if (shouldAutoDelete) {
      deleteAndGoBack()
    } else {
      navigateReplace(ROUTES.FORMS.GOAL.CREATE.SELECT_LEVEL, {}, false)
    }
  }

  const onSubmitted = () => {
    if (values.status.id === Statuses.draft) {
      navigateTo(backUrl)
    } else {
      navigateReplace(
        pathToUrl(
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW
            : ROUTES.FORMS.GOAL.PREVIEW,
          { id: values.id },
        ),
      )
    }
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        backButton={autoDeletePending ? <Spinner /> : undefined}
        withVerticalSpacing
        onClickBack={handleBack}
        hideGlobalSearch={isOnboarding}
      />
      <FormObserverProvider>
        <GoalFormPageBody
          onDirtyChanged={setIsDirty}
          defaultReviewCycle={reviewCycles?.results.find(isDefaultCycle)}
          onSubmitted={onSubmitted}
          performanceSettings={performanceSettings}
        />
      </FormObserverProvider>
      {confirmationDialog}
    </PageWrapper>
  ) : null
}
