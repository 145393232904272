import React, { useState } from 'react'
import { ActionButton, Button, Item, Header, Popup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { createOnboarding, getOnboardingList } from '@src/api/onboardingEmployeesV2'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useTable } from '@components/Table/hooks'
import { IdAndName } from '@src/interfaces'
import { EmployeeInterface } from '@src/interfaces/employees'
import { OnboardingInterface, OnboardingStatus } from '@src/interfaces/onboardingV2'
import { pathToUrl } from '@src/utils/router'

interface Props {
  data: EmployeeInterface
}

export const OnboardingWarningV2 = ({ data }: Props) => {
  const [isPending, setIsPending] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const [template, setTemplate] = useState<IdAndName | null>(null)

  const initialFilters = [
    {
      columnName: 'employee_id',
      filters: [
        {
          id: data.id,
          name: String(data.id),
        },
      ],
      nonResettable: true,
    },
  ]

  const table = useTable<OnboardingInterface>(
    {
      getItems: getOnboardingList,
    },
    initialFilters,
  )

  const startedOnboarding = table.data.filter(
    onboarding =>
      onboarding.status.id === OnboardingStatus.not_started ||
      OnboardingStatus.in_progress,
  )

  const handleCreate = async () => {
    setIsPending(true)

    try {
      const res = await createOnboarding(data.id, template?.id)

      if (res.data.id) {
        navigateTo(
          pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, {
            employeeId: String(data.id),
            id: data.id,
            onboardingId: res.data.id,
          }),
        )
      }
    } finally {
      setIsPending(false)
    }
  }

  if (table.loading) {
    return null
  }

  if (!startedOnboarding.length) {
    return (
      <>
        <Item>
          <Item.Content>
            <Item.Title>Candidate is ready to begin onboarding</Item.Title>
          </Item.Content>
          <Item.Side>
            <ActionButton onClick={() => setOpenPopup(true)} variant="accent">
              Start onboarding
            </ActionButton>
          </Item.Side>
        </Item>

        <Popup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          variant="bottom-sheet"
        >
          <Header variant="bottom-sheet">
            <Header.Title>{`Onboard ${data.full_name}`}</Header.Title>
          </Header>
          <RadioSelectInput
            label="Onboarding template"
            onChange={option => {
              if (option) {
                setTemplate(option)
              }
            }}
            selector={selectorKeys.employee_onboarding_templates}
            value={template}
          />
          <Popup.Actions horizontal>
            <Button onClick={() => setOpenPopup(false)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleCreate} pending={isPending}>
              Continue
            </Button>
          </Popup.Actions>
        </Popup>
      </>
    )
  }

  if (startedOnboarding.length) {
    return (
      <Item>
        <Item.Content>
          <Item.Title>{data.first_name} is currently going through onboarding</Item.Title>
        </Item.Content>
        <Item.Side>
          <ActionButton
            variant="accent"
            to={pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, {
              id: data.id,
              onboardingId: startedOnboarding[0].id,
            })}
            use={InternalLink}
          >
            Review progress
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }

  return (
    <Item>
      <Item.Content>
        <Item.Title>Employee is waiting for start-date</Item.Title>
      </Item.Content>
    </Item>
  )
}
