import React, { useState } from 'react'
import {
  ActionButton,
  Box,
  Button,
  Color,
  Flex,
  StatusPopup,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { ChevronDown, ChevronUp, ExclamationTriangle, Plus } from '@revolut/icons'
import { z } from 'zod'
import { set, startCase, toLower, intersectionBy } from 'lodash'
import { connect } from 'lape'
import pluralize from 'pluralize'
import { css } from 'styled-components'
import { AxiosError } from 'axios'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import MultiInput from '@src/components/Inputs/MultiInput/MultiInput'
import { PageActions } from '@src/components/Page/PageActions'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import { TableNames } from '@src/constants/table'
import { CellInsertParams, CellTypes } from '@src/interfaces/data'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { navigateReplace } from '@src/actions/RouterActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useGetSelectors } from '@src/api/selectors'
import {
  InviteTeamResponse,
  InviteTeamTableErrorInterface,
  InviteTeamTableInterface,
} from '@src/interfaces/platformOnboarding'
import { inviteTeamMembers } from '@src/api/platformOnboarding'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import Form from '@src/features/Form/Form'
import { arrayErrorsToFormError } from '@src/utils/form'
import { pushError } from '@src/store/notifications/actions'
import { AccessGroupSelectorOption } from '@src/components/AccessGroup/AccessGroupSelectorOption'

const INVALID_EMAIL_ERROR = 'Enter a valid email address.'
const EMAIL_ALREADY_EXISTS = 'Email already exists.'
const NAME_REQUIRED_ERROR = 'This field may not be blank.'

const validateEmail = (email: string, emails: string[]) =>
  z
    .string()
    .email({
      message: INVALID_EMAIL_ERROR,
    })
    .refine(mail => emails.filter(m => m === mail).length < 2, {
      message: EMAIL_ALREADY_EXISTS,
    })
    .safeParse(email)

const validateName = (name: string) =>
  z.string().trim().min(1, { message: NAME_REQUIRED_ERROR }).safeParse(name)

type Field = keyof Pick<InviteTeamTableInterface, 'full_name' | 'email'>
type OnChangeTableValue = (id: number, field: Field, value: string) => void
type OnClearError = (id: number, field: Field) => void

const getError = (
  row: InviteTeamTableInterface,
  errors: (InviteTeamTableErrorInterface | null)[],
  field: Field,
) => errors?.find(err => err?.id === row.id)?.[field]

interface TableCellProps {
  data: InviteTeamTableInterface
  tableErrors: InviteTeamTableErrorInterface[]
  field: Field
  onChangeTableValue: OnChangeTableValue
  onClearError: OnClearError
}

const fieldToLabelFieldMap = {
  email: 'Email',
  full_name: 'Name',
}

const TableCell = ({
  data,
  tableErrors,
  field,
  onChangeTableValue,
  onClearError,
}: TableCellProps) => {
  const tooltip = useTooltip()
  const [value, setValue] = useState(data[field])

  const error = getError(data, tableErrors, field)

  return (
    <Flex alignItems="center" gap="s-8">
      <Box flex="1">
        <EditableCell
          type={TableCellInputType.text}
          value={value}
          onChange={val => {
            if (error) {
              onClearError(data.id, field)
            }
            setValue(val as string)
          }}
          onBlur={val => {
            onChangeTableValue(data.id, field, val as string)
          }}
        />
      </Box>
      {error ? (
        <>
          <ExclamationTriangle
            color={Color.ERROR}
            aria-label={`${fieldToLabelFieldMap[field]} error`}
            {...tooltip.getAnchorProps()}
          />
          <Tooltip {...tooltip.getTargetProps()}>{error}</Tooltip>
        </>
      ) : null}
    </Flex>
  )
}

export const convertEmailToName = (email: string) =>
  startCase(toLower(email.split('@')[0]))
    .split(' ')
    ?.filter((_, index) => index < 2)
    .join(' ') || ''

const CellErrorCss = css`
  background-color: ${Token.color.red_30} !important;
`

const row = (
  tableData: InviteTeamTableInterface[],
  tableErrors: InviteTeamTableErrorInterface[],
  onDelete: (index: number) => void,
  groupOptions: IdAndName[],
  onChangeTableValue: OnChangeTableValue,
  onClearError: OnClearError,
): EditableRowInterface<InviteTeamTableInterface> => ({
  cells: [
    {
      type: CellTypes.insert,
      idPoint: 'full_name',
      dataPoint: 'full_name',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Name',
      width: 300,
      wrapperCss: data =>
        getError(data, tableErrors, 'full_name') ? CellErrorCss : undefined,
      insert: ({ data }: CellInsertParams<InviteTeamTableInterface>) => {
        return (
          <TableCell
            data={data}
            tableErrors={tableErrors}
            field="full_name"
            onChangeTableValue={onChangeTableValue}
            onClearError={onClearError}
          />
        )
      },
    },
    {
      type: CellTypes.insert,
      idPoint: 'email',
      dataPoint: 'email',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Email',
      width: 300,
      wrapperCss: data =>
        getError(data, tableErrors, 'email') ? CellErrorCss : undefined,
      insert: ({ data }: CellInsertParams<InviteTeamTableInterface>) => {
        return (
          <TableCell
            data={data}
            tableErrors={tableErrors}
            field="email"
            onChangeTableValue={onChangeTableValue}
            onClearError={onClearError}
          />
        )
      },
    },
    {
      type: CellTypes.insert,
      idPoint: 'access_group',
      dataPoint: 'access_group',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Access group',
      insert: ({ data }: CellInsertParams<InviteTeamTableInterface>) => {
        const currentRow = tableData.find(item => item.email === data.email)

        if (!currentRow) {
          return null
        }

        return (
          <RadioSelectInput<IdAndName & { description?: string }>
            label="Access group"
            value={currentRow.access_group}
            onChange={option => {
              if (option) {
                currentRow.access_group = option
              }
            }}
            options={groupOptions.map(value => ({ label: value.name, value }))}
            renderInput={(open, setOpen, ref) => (
              <Flex
                onClick={() => setOpen(!open)}
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                use="button"
                type="button"
                ref={ref}
              >
                <Text>{currentRow.access_group?.name || 'Select group'}</Text>
                {open ? (
                  <ChevronUp color={Color.GREY_TONE_50} size={16} />
                ) : (
                  <ChevronDown color={Color.GREY_TONE_50} size={16} />
                )}
              </Flex>
            )}
          >
            {option => <AccessGroupSelectorOption {...option.value} />}
          </RadioSelectInput>
        )
      },
      width: 300,
    },
    {
      type: CellTypes.insert,
      idPoint: 'action',
      dataPoint: '',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Action',
      insert: ({ data }: CellInsertParams<InviteTeamTableInterface>) => (
        <TableActionButton onClick={() => onDelete(data.id)}>Delete</TableActionButton>
      ),
      width: 100,
    },
  ],
})

const PAGE_TITLE = 'Invite your team'

interface FormData {
  data: InviteTeamTableInterface[]
  errors: InviteTeamTableErrorInterface[]
}

const InviteTeamRoute = () => {
  const { values } = useLapeContext<FormData>()
  const { forceErrors } = useSafeFormValidator()

  if (!values.data) {
    values.data = []
    values.errors = []
  }

  const hasInitialData = values.data && values.data?.length > 0

  const [viewTable, setViewTable] = useState(hasInitialData)
  const [isEditing, setIsEditing] = useState(hasInitialData)
  const [successPopupState, setSuccessPopupState] = useState({
    open: false,
    sentCount: 0,
  })
  const [emails, setEmails] = useState<{ email: string; isValid: boolean }[]>([])

  const { data: groups = [] } = useGetSelectors<IdAndName & { description?: string }>(
    selectorKeys.groups,
  )

  if (groups.length > 0) {
    const usersWithoutGroup = values.data.filter(user => !user.access_group)
    if (usersWithoutGroup.length > 0) {
      usersWithoutGroup.forEach(user => {
        /** 1st `groups` item returned by the API is the default one that should be preselected */
        user.access_group = groups[0]
      })
    }
  }

  const validateEmailFunc = (email: string) =>
    validateEmail(email, [
      ...values.data.map(data => data.email),
      ...emails.map(m => m.email),
    ])

  const onSubmit = async () => {
    const data = values.data.map(({ id, access_group, ...user }) => ({
      ...user,
      group_id: access_group?.id,
    }))
    const result = await inviteTeamMembers(data)
    return Promise.resolve(result.data)
  }

  const onSubmitError = (error: AxiosError) => {
    const submitErrors = arrayErrorsToFormError(error?.response?.data)
    if (Array.isArray(submitErrors)) {
      const errors = submitErrors.map(
        (apiError: InviteTeamTableErrorInterface, index) => ({
          id: values.data[index].id,
          full_name: apiError.full_name,
          email: apiError.email,
        }),
      )
      set(values, 'errors', errors)
    } else {
      pushError({ error })
    }
  }

  const onDelete = (id: number) => {
    values.data = values.data.filter(item => item.id !== id)
  }

  const onChangeMultiInput = (value: string[]) => {
    /** Handle remove item */
    if (value.length < emails.length) {
      setEmails(
        intersectionBy(
          emails,
          value.map(email => ({ email })),
          'email',
        ),
      )
      return
    }
    /** Handle add items */
    if (value.length > emails.length) {
      setEmails([
        ...emails,
        ...value.slice(emails.length - value.length).map(email => ({
          email,
          isValid: validateEmailFunc(email).success,
        })),
      ])
      return
    }
    /** Handle value change */
    const editedEmails = emails.map((email, index) => {
      const current = value[index]
      if (email.email === current) {
        return email
      }
      return {
        email: current,
        isValid: validateEmailFunc(current).success,
      }
    })
    setEmails(editedEmails)
  }

  const goToAccessManagementPage = () =>
    navigateReplace(ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS)

  const onAfterSubmit = (response: InviteTeamResponse) => {
    /** Clearing table data */
    localStorage.removeItem(window.location.pathname)
    setSuccessPopupState({ open: true, sentCount: response.count })
  }

  /** Hacky stuff, but the `wrapperCss` on columns does not get called, and error CSS not updated, unless `values.data` changes */
  const rerenderTable = () => {
    values.data = [...values.data]
  }

  const onChangeTableValue: OnChangeTableValue = (id, field, value) => {
    const changeRowIndex = values.data?.findIndex(r => r.id === id)

    if (changeRowIndex > -1) {
      const changeRow = values.data[changeRowIndex]
      set(changeRow, field, value)

      const validator = {
        email: validateEmailFunc,
        full_name: validateName,
      }
      const validationResult = validator[field](value)
      if (!validationResult.success) {
        set(values.errors, changeRowIndex, {
          ...values.errors[changeRowIndex],
          id: changeRow.id,
          [field]: validationResult.error.issues[0]?.message,
        })
        rerenderTable()
      }
    }
  }

  const onClearError: OnClearError = (id, field) => {
    const error = values.errors?.find(r => r?.id === id)
    if (error) {
      set(error, field, undefined)
      rerenderTable()
    }
  }

  if (viewTable) {
    return (
      <>
        <PageWrapper>
          <PageHeader
            title={PAGE_TITLE}
            subtitle="Team members are pre-assigned access groups. You can edit these below"
            backUrl={ROUTES.MAIN}
          />

          <VStack space="s-16">
            <ActionButton onClick={() => setViewTable(false)} useIcon={Plus}>
              Add more members
            </ActionButton>
            <Box>
              <LapeEditableTable
                name={TableNames.InviteTeam}
                dataFieldName="data"
                count={values.data?.length || 0}
                rowHeight="large"
                hideCountAndButtonSection
                useWindowScroll
                row={row(
                  values.data,
                  values.errors,
                  onDelete,
                  groups,
                  onChangeTableValue,
                  onClearError,
                )}
              />
            </Box>
          </VStack>

          {values.data.length > 0 ? (
            <PageActions>
              <NewSaveButtonWithPopup<InviteTeamResponse>
                onAfterSubmit={onAfterSubmit}
                noPopup
                onClick={onSubmit}
                onSubmitError={onSubmitError}
              >
                Send invites
              </NewSaveButtonWithPopup>
            </PageActions>
          ) : null}
        </PageWrapper>

        <StatusPopup
          open={successPopupState.open}
          variant="success-result"
          // @ts-expect-error
          labelButtonClose="Close"
          onClose={goToAccessManagementPage}
        >
          <StatusPopup.Title>
            Invitation sent to{' '}
            <Text color={Color.BLUE}>
              {successPopupState.sentCount} team{' '}
              {pluralize('member', successPopupState.sentCount)}
            </Text>
          </StatusPopup.Title>
          <StatusPopup.Description>
            They will receive an invitation email to sign up immediately
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={goToAccessManagementPage}>
              Go to access management page
            </Button>
            <Button onClick={() => navigateReplace(ROUTES.MAIN)} variant="secondary">
              Return to dashboard
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>
      </>
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title={PAGE_TITLE}
        subtitle="Type invitee emails below, or paste as many as you need at once"
        backUrl={ROUTES.MAIN}
        onClickBack={isEditing ? () => setViewTable(true) : undefined}
      />

      <PageBody>
        <MultiInput
          label="Invitee's email"
          value={emails.map(e => e.email)}
          onChange={onChangeMultiInput}
          inputErrors={emails.map(({ email, isValid }) => {
            if (isValid) {
              return null
            }
            const result = validateEmailFunc(email)
            if (result.success) {
              return null
            }
            return result.error.issues[0].message
          })}
          splitOnPaste
          data-name="email"
        />
      </PageBody>

      <PageActions>
        <Button
          onClick={() => {
            const cleanedUpEmails = emails
              .map(email => ({ email: email.email.trim(), isvalid: email.isValid }))
              .filter(({ email }) => email.length)

            if (cleanedUpEmails.length) {
              const emailsWithValidation = cleanedUpEmails.map(({ email }) => ({
                email,
                isValid: validateEmailFunc(email).success,
              }))

              if (emailsWithValidation.find(({ isValid }) => !isValid)) {
                setEmails(emailsWithValidation)
                forceErrors(
                  emailsWithValidation.reduce(
                    (acc, val, i) =>
                      val.isValid ? acc : { ...acc, [`email.${i}`]: true },
                    {},
                  ),
                )
                return
              }

              cleanedUpEmails.forEach(({ email }) => {
                values.data.push({
                  full_name: convertEmailToName(email),
                  email,
                  id: Math.random(),
                })
              })
            }

            setViewTable(true)
            setIsEditing(true)
            setEmails([])
          }}
          elevated
        >
          Continue
        </Button>
      </PageActions>
    </PageWrapper>
  )
}

export const InviteTeam = connect(() => (
  <Form>
    <InviteTeamRoute />
  </Form>
))
