import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '@src/api/index'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  WorkScheduleApprovalsStepInterface,
  WorkScheduleBasicStepInterface,
  WorkScheduleEligibilityStepInterface,
  WorkScheduleReviewStepInterface,
  WorkSchedulesExtraHoursStepInterface,
} from '@src/interfaces/workSchedule'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'

export const workScheduleBasicRequests: RequestInterfaceNew<WorkScheduleBasicStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/basicStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/basicStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/basicStep`, data),
  }

export const useGetWorkScheduleBasics = (id: string | undefined) =>
  useFetch<WorkScheduleBasicStepInterface>(
    id ? `${API.TIME_OFF}/workSchedules/basicStep/${id}` : null,
  )

export const workScheduleExtraHoursRequests: RequestInterfaceNew<WorkSchedulesExtraHoursStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/extraHoursStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.TIME_OFF}/workSchedules/extraHoursStep/${id}`,
        data,
      ),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/extraHoursStep`, data),
  }

export const workScheduleApprovalsRequests: RequestInterfaceNew<WorkScheduleApprovalsStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/approvalsStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/approvalsStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/approvalsStep`, data),
  }

export const workScheduleEligibilityRequests: RequestInterfaceNew<WorkScheduleEligibilityStepInterface> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/workSchedules/eligibility/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/workSchedules/eligibility/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/workSchedules/eligibility`, data),
  }

export const getWorkScheduleReviewEligibilityListRequests =
  (id: number) =>
  async ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<WorkScheduleReviewStepInterface>>(
      `${API.TIME_OFF}/workSchedules/${id}/review/eligibleEmployees`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )
