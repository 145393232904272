import React, { useEffect, useRef, useState } from 'react'
import {
  FinalGrade,
  ManagerRecommendationInterface,
  ReviewCategory,
  ReviewDataInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import set from 'lodash/set'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import BottomText from '@components/Inputs/partials/BottomText'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { FeedbackCommon } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/FeedbackCommon'
import { useGetProbationMissingValues } from '@src/pages/Forms/ProbationLayout/utils'
import { shouldScrollToError } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { GradesMapInterface } from '@src/utils/grades'

export interface FeedbackProps {
  type?: RecommendationTypes
  recommendationData?: ManagerRecommendationInterface
  reviews?: ManagerRecommendationInterface[]
  showBeforeSubmitCheckError?: boolean
  showMissingJustificationError?: boolean
  gradesMap: GradesMapInterface
}

export const Feedback = connect(
  ({
    recommendationData,
    type,
    reviews,
    showBeforeSubmitCheckError,
    showMissingJustificationError,
    gradesMap,
  }: FeedbackProps) => {
    const { values, errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()
    const [prosElement, setProsElement] = useState<HTMLTextAreaElement | null>(null)
    const ref = useRef<HTMLDivElement>(null)

    const reviewData = values.review_data
    const formErrors = errors as FormError<ReviewScorecardInterface>

    const user = useSelector(selectUser)
    const reviewedEmployeeId = values.reviewed_employee.id
    const isSelfReview = reviewedEmployeeId === user.id
    const { missingRating, missingBarRaiser } = useGetProbationMissingValues(values)

    const recommendedGrades = useRecommendedGradesContext()
    const recommendedGrade = recommendedGrades.recommendedGrade
    const selectedGrade = recommendationData?.employee_project_performance.value
    const recommendationSelected =
      recommendedGrade && selectedGrade ? recommendedGrade === selectedGrade : true

    const justificationRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
      if (!(reviewData as ReviewDataInterface)?.overall_feedback) {
        set(values, 'review_data.overall_feedback', {
          pros: [],
          cons: [],
        })
      }

      if (values.visible_to_reviewee === null) {
        values.visible_to_reviewee = false
      }
    }, [])

    useEffect(() => {
      if (submitFailed || missingRating) {
        let key

        switch (type) {
          case RecommendationTypes.LM:
            key = `review_data.line_manager_extra_section`
            break

          case RecommendationTypes.FM:
            key = `review_data.functional_manager_extra_section`
            break

          case RecommendationTypes.PEER:
            key = `review_data.peer_extra_section`
            break

          case RecommendationTypes.PIP:
            key = 'review_data.recommendation_extra_section'
            break

          case RecommendationTypes.PIP_V1:
            key = `review_data.pip_extra_section`
            break
        }

        const scrollToMissingRating =
          showBeforeSubmitCheckError && missingRating && !missingBarRaiser
        const scrollToJustification = shouldScrollToError(
          errors,
          `${key}.employee_project_performance.justification`,
        )
        const scrollToValidationError = shouldScrollToError(
          errors,
          `${key}.employee_project_performance.value`,
        )

        if (scrollToJustification) {
          justificationRef?.current?.scrollIntoView({ behavior: 'smooth' })
        } else if (scrollToMissingRating || scrollToValidationError) {
          ref?.current?.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, [
      formErrors.review_data,
      missingRating,
      missingBarRaiser,
      showBeforeSubmitCheckError,
    ])

    useEffect(() => {
      const scrollToFeedback = shouldScrollToError(errors, 'review_data.overall_feedback')
      if (scrollToFeedback) {
        !!prosElement && prosElement.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.overall_feedback])

    const onRadioChange = (value: FinalGrade) => {
      let key

      switch (type) {
        case RecommendationTypes.LM:
          key =
            'review_data.line_manager_extra_section.employee_project_performance.value'
          break

        case RecommendationTypes.FM:
          key =
            'review_data.functional_manager_extra_section.employee_project_performance.value'
          break

        case RecommendationTypes.PEER:
          key = 'review_data.peer_extra_section.employee_project_performance.value'
          break

        case RecommendationTypes.PIP:
          key =
            'review_data.recommendation_extra_section.employee_project_performance.value'
          break

        case RecommendationTypes.PIP_V1:
          key = `review_data.pip_extra_section.employee_project_performance.value`
          break
      }

      if (key) {
        set(values, key, value)
      }
    }

    const renderError = () => {
      if (!submitFailed && !missingRating) {
        return null
      }

      let key

      switch (type) {
        case RecommendationTypes.LM:
          key = `review_data.line_manager_extra_section.employee_project_performance.value`
          break

        case RecommendationTypes.FM:
          key = `review_data.functional_manager_extra_section.employee_project_performance.value`
          break

        case RecommendationTypes.PEER:
          key = `review_data.peer_extra_section.employee_project_performance.value`
          break

        case RecommendationTypes.PIP:
          key = `review_data.recommendation_extra_section.employee_project_performance.value`
          break

        case RecommendationTypes.PIP_V1:
          key = `review_data.pip_extra_section.employee_project_performance.value`
          break
      }

      const errorMsg = <BottomText error="Please select one of the options" />

      if (showBeforeSubmitCheckError && missingRating) {
        return errorMsg
      }

      if (!key || !get(errors, key) || get(values, key)) {
        return null
      }

      return errorMsg
    }

    const renderJustification = () => {
      let textAreaName: string = ''

      switch (type) {
        case RecommendationTypes.LM:
          textAreaName =
            'review_data.line_manager_extra_section.employee_project_performance.justification'
          break

        case RecommendationTypes.FM:
          textAreaName =
            'review_data.functional_manager_extra_section.employee_project_performance.justification'
          break

        case RecommendationTypes.PEER:
          textAreaName =
            'review_data.peer_extra_section.employee_project_performance.justification'
          break

        case RecommendationTypes.PIP:
          textAreaName =
            'review_data.recommendation_extra_section.employee_project_performance.justification'
          break

        case RecommendationTypes.PIP_V1:
          textAreaName =
            'review_data.pip_extra_section.employee_project_performance.justification'
          break
      }

      const errorMsg = get(errors, textAreaName)

      return (
        <>
          <LapeNewTextArea
            label="Grade justification"
            name={textAreaName}
            hideOptional={
              values.category === ReviewCategory.PIP_V2 || !recommendationSelected
            }
            hasError={!!errorMsg}
            elementRef={justificationRef}
            message={errorMsg}
          />
          {showMissingJustificationError && (
            <BottomText error="Please add justification" />
          )}
        </>
      )
    }

    const feedbackContent = (
      <OverallFeedback
        noMargin
        pros={(reviewData as ReviewDataInterface)?.overall_feedback?.pros}
        cons={(reviewData as ReviewDataInterface)?.overall_feedback?.cons}
        onChangePros={val => {
          set(values, 'review_data.overall_feedback.pros', val.split('\n'))
        }}
        onChangeCons={val => {
          set(values, 'review_data.overall_feedback.cons', val.split('\n'))
        }}
        onGetProsElement={elem => {
          setProsElement(elem)
        }}
      />
    )

    return (
      <>
        <FeedbackCommon
          recommendationData={recommendationData}
          renderJustification={renderJustification}
          onRadioChange={onRadioChange}
          renderError={renderError}
          reviews={reviews}
          isSelfReview={isSelfReview}
          feedbackContent={feedbackContent}
          recommendationRef={ref}
          reviewData={reviewData}
          gradesMap={gradesMap}
        />
      </>
    )
  },
)
