import React, { useEffect, useState } from 'react'
import HierarchyTree from '@components/Hierarchy/HierarchyTree'
import styled from 'styled-components'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { newTabTo } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  getDirectHierarchyEmployee,
  getEmployeeHierarchyDirect,
  getEmployeeHierarchyFunctional,
  getFunctionalHierarchyEmployee,
} from '@src/api/functions'
import { rolesRequests } from '@src/api/roles'
import { PermissionTypes } from '@src/store/auth/types'
import {
  Absolute,
  Box,
  Checkbox,
  CheckboxGroup,
  TabBar,
  Text,
  Token,
} from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

const HierarchyContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
`

const EmployeeSelector = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 32px;
`

const NoInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 35px;
  font-size: 15px;
  color: ${Token.color.greyTone50};
  width: 100%;
  height: 100%;
`

enum Tabs {
  Reporting = 'Reporting',
  Functional = 'Functional',
}

enum Filters {
  PerformanceGrades,
  IncludeRequisitions,
}

interface Props {
  data: EmployeeInterface
}

interface HierarchyFilterInterface {
  showRequisitions?: boolean
  showPerformance?: boolean
}

const Hierarchy = ({ data }: Props) => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(Tabs.Reporting)
  const [showTags, setShowTags] = useState(false)
  const [filters, setFilters] = useState<HierarchyFilterInterface>({})
  const [checkBoxValue, setCheckBoxValue] = useState<Filters[]>()
  const [rootData, setRootData] = useState<HierarchyNodeInterface>()
  const [functionId, setFunctionId] = useState<number>()
  const [showPerformanceSwitch, setShowPerformanceSwitch] = useState(false)
  const { data: performanceSettings } = useGetPerformanceSettings()

  const handleHierarchyTypeChange = (value: Tabs) => {
    setType(value)
  }
  useEffect(() => {
    fetchRootData()
  }, [type, filters.showRequisitions])

  const fetchRootData = async () => {
    try {
      setLoading(true)
      let result
      if (data.team?.id) {
        const position = await rolesRequests.getItem(data.position?.id)
        if (position?.data?.function?.id) {
          setFunctionId(position?.data?.function?.id)
        } else if (type === Tabs.Functional) {
          setType(Tabs.Reporting)
        }
      }
      if (type === Tabs.Reporting) {
        result = await getEmployeeHierarchyDirect(data.id, !!filters.showRequisitions)
      } else {
        result = await getEmployeeHierarchyFunctional(data.id)
      }
      if (
        result?.data?.field_options?.permissions?.includes?.(
          PermissionTypes.ViewReportsPerformanceEmployees,
        )
      ) {
        setShowPerformanceSwitch(true)
      }
      setRootData(result.data)
    } finally {
      setLoading(false)
    }
  }

  const onChildrenRequest = async (node: HierarchyNodeInterface) => {
    let result

    if (type === Tabs.Reporting) {
      result = await getDirectHierarchyEmployee(node.id, !!filters.showRequisitions)
    } else if (functionId) {
      result = await getFunctionalHierarchyEmployee(functionId, node.id)
    }

    if (result?.data.reports) {
      return result?.data.reports
    }

    return []
  }

  const handleTitleClick = (node: HierarchyNodeInterface) => {
    if (node.is_requisition) {
      newTabTo(ROUTES.FORMS.REQUISITION.ROLE, { id: node.id })
    } else {
      newTabTo(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: node.id })
    }
  }

  const handleFilterChange = (filter: Filters[]) => {
    setCheckBoxValue(filter)
    const hierarchyFilters: HierarchyFilterInterface = {}
    if (!showTags && filter.includes(Filters.PerformanceGrades)) {
      setShowTags(true)
    } else if (showTags && !filter.includes(Filters.PerformanceGrades)) {
      setShowTags(false)
    }

    hierarchyFilters.showRequisitions = filter.includes(Filters.IncludeRequisitions)
    hierarchyFilters.showPerformance = filter.includes(Filters.PerformanceGrades)

    setFilters(hierarchyFilters)
  }

  const showTree = Boolean(loading || rootData)

  return (
    <HierarchyContainer>
      {showTree ? (
        <HierarchyTree
          onNodeTitleClick={handleTitleClick}
          showTags={showTags}
          onChildrenRequest={onChildrenRequest}
          noWrongFunctional
          rootNodeData={rootData}
          loading={loading}
        />
      ) : (
        <NoInfoContainer>
          There is no hierarchy information for this employee
        </NoInfoContainer>
      )}
      <EmployeeSelector>
        {performanceSettings?.enable_functional_management &&
          functionId &&
          data.line_manager?.id && (
            <TabBar variant="segmented fit">
              <TabBar.Item
                onClick={() => handleHierarchyTypeChange(Tabs.Reporting)}
                aria-selected={type === Tabs.Reporting}
              >
                Reporting
              </TabBar.Item>
              <TabBar.Item
                onClick={() => handleHierarchyTypeChange(Tabs.Functional)}
                aria-selected={type === Tabs.Functional}
              >
                Functional
              </TabBar.Item>
            </TabBar>
          )}
        <Absolute top="60px">
          <CheckboxGroup value={checkBoxValue} onChange={handleFilterChange}>
            {group => (
              <>
                {showPerformanceSwitch && rootData && (
                  <Box mb="s-8">
                    <Checkbox
                      {...group.getInputProps({ value: Filters.PerformanceGrades })}
                    >
                      <Text whiteSpace="nowrap" use="div">
                        View performance grades
                      </Text>
                    </Checkbox>
                  </Box>
                )}
                {type === Tabs.Reporting && (
                  <Box mb="s-8">
                    <Checkbox
                      {...group.getInputProps({
                        value: Filters.IncludeRequisitions,
                      })}
                    >
                      <Text whiteSpace="nowrap" use="div">
                        Show requisitions
                      </Text>
                    </Checkbox>
                  </Box>
                )}
              </>
            )}
          </CheckboxGroup>
        </Absolute>
      </EmployeeSelector>
    </HierarchyContainer>
  )
}

export default Hierarchy
