import React from 'react'
import { Box, Checkbox, Item, Tooltip, useTooltip } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import HiringStageDetails from '@src/components/HiringStages/HiringStageDetails'

type HiringStageProps = {
  disabled: boolean
  disableSelectingCVScreeningStages?: boolean
  hiringStage: HiringProcessInterface
  selected?: HiringProcessInterface[]
  onSelect?: (selected: HiringProcessInterface[]) => void
}

export const HiringStage = ({
  disabled,
  disableSelectingCVScreeningStages,
  hiringStage,
  selected,
  onSelect,
}: HiringStageProps) => {
  const tooltip = useTooltip()
  const selectedStage = selected?.find(({ id }) => id === hiringStage.id)
  const disableSelectingCVScreening =
    disableSelectingCVScreeningStages &&
    selectedStage?.id !== hiringStage.id &&
    hiringStage?.stage_type?.id === 'cv_screening'
  return (
    <Box key={hiringStage.id} my="s-8" {...tooltip.getAnchorProps()}>
      <Item
        use={disabled || disableSelectingCVScreening ? undefined : 'label'}
        inactive={disabled || disableSelectingCVScreening}
      >
        {onSelect && !!selected && (
          <Item.Prefix>
            <Checkbox
              disabled={disabled || disableSelectingCVScreening}
              checked={!!selectedStage}
              onClick={() => {
                onSelect(
                  selectedStage
                    ? selected.filter(({ id }) => id !== hiringStage.id)
                    : [...selected, hiringStage],
                )
              }}
            />
          </Item.Prefix>
        )}
        <Item.Content>
          <Item.Title>{hiringStage.title}</Item.Title>
          <Item.Description>
            <HiringStageDetails hiringStage={hiringStage} />
          </Item.Description>
        </Item.Content>
      </Item>
      {disableSelectingCVScreening && (
        <Tooltip {...tooltip.getTargetProps()}>
          You cannot add more than one CV screening stage to the hiring process
        </Tooltip>
      )}
    </Box>
  )
}
