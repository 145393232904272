import React from 'react'
import { Box, ErrorWidget, ItemSkeleton } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { HiringStage } from '@src/components/HiringStagesBank/HiringStage'

type RoleHiringStagesProps = {
  disabled: boolean
  disableSelectingCVScreeningStages: boolean
  hiringStages?: HiringProcessInterface[]
  loadingHiringStages: boolean
  selected: HiringProcessInterface[]
  specialisationName?: string
  onChangeSelected: (selected: HiringProcessInterface[]) => void
}

export const RoleHiringStages = ({
  disabled,
  disableSelectingCVScreeningStages,
  hiringStages,
  loadingHiringStages,
  selected,
  specialisationName,
  onChangeSelected,
}: RoleHiringStagesProps) => {
  return (
    <Box>
      {hiringStages?.map(hiringStage => {
        return (
          <HiringStage
            key={hiringStage.id}
            hiringStage={hiringStage}
            disabled={disabled}
            disableSelectingCVScreeningStages={disableSelectingCVScreeningStages}
            selected={selected}
            onSelect={onChangeSelected}
          />
        )
      })}
      {loadingHiringStages && <ItemSkeleton />}
      {!loadingHiringStages && !hiringStages?.length && (
        <Box my="s-8">
          <ErrorWidget>
            <ErrorWidget.Image />
            <ErrorWidget.Title>
              No Role hiring stages found{' '}
              {specialisationName ? `for ${specialisationName}` : null}
            </ErrorWidget.Title>
          </ErrorWidget>
        </Box>
      )}
    </Box>
  )
}
