import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Box,
  InputGroup,
  StatusPopup,
  Subheader,
  TabBar,
  Text,
  VStack,
} from '@revolut/ui-kit'
import {
  AvailableInterviewerSlot,
  DurationUnitType,
  InterviewStageWithoutRoundInterface,
  ScheduleSidebarModeType,
  SchedulingType,
} from '@src/interfaces/interviewTool'
import { OptionInterface } from '@src/interfaces/selectors'
import PreviewScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/PreviewScheduleSidebar'
import EditScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/EditScheduleSidebar'
import ScheduledInterview from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledInterview'
import Loader from '@components/CommonSC/Loader'
import {
  editInterview,
  useGetSchedulingInterview,
} from '@src/api/recruitment/interviewerScheduling'
import { DateFilterOptions } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotDateFilter'
import EditCustomDate from '@src/pages/Forms/Candidate/ScheduleSidebar/EditCustomDate'
import EditSlotsDate from '@src/pages/Forms/Candidate/ScheduleSidebar/EditSlotsDate'
import EditManualDate from '@src/pages/Forms/Candidate/ScheduleSidebar/EditManualDate'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'
import CalendarSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/CalendarSelect'
import {
  dateToCustomDate,
  customDateToDate,
  getPendingSchedulingStages,
  getTimeZoneId,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { ExtensionApiHandlerContext } from '@src/utils/extension'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import EmailScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/EmailScheduleSidebar'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import SideBar from '@components/SideBar/SideBar'

type Props = {
  stages: InterviewStageWithoutRoundInterface[]
  roundId?: number
  onClose?: () => void
  totalStagesCount: number
  selectedStageId?: number
  onRefresh?: () => void
  onSchedulingSuccess?: (stage: InterviewStageWithoutRoundInterface) => void
  initialMode?: ScheduleSidebarModeType
  onChangeInitialMode?: (mode: ScheduleSidebarModeType) => void
  interviewId?: number
  isPrepCall?: boolean
  isStageDisabled?: boolean
}

export enum Tabs {
  Automatic = 'Automatic slots',
  Custom = 'Custom available slot',
  Manual = 'Manual',
}

const getSelectedStage = (
  stages: InterviewStageWithoutRoundInterface[],
  selectedStageId?: number,
) => stages.find(item => item.id === selectedStageId) || stages[0]

interface SendCandidateEmailWithoutRoundInterface
  extends Omit<SendCandidateEmailInterface, 'interview_stage'> {
  interview_stage?: InterviewStageWithoutRoundInterface
}

// the component should be controlled outside, e.g. should not be mounted if closed, as we have a lot of logic here happening before the opening
const ScheduleSidebar = ({
  stages,
  roundId,
  selectedStageId,
  totalStagesCount,
  onRefresh,
  initialMode,
  onChangeInitialMode,
  onSchedulingSuccess,
  isStageDisabled,
  interviewId,
  isPrepCall,
}: Omit<Props, 'onClose'>) => {
  const { values } = useLapeContext<SendCandidateEmailWithoutRoundInterface>()
  const { sendAnalyticsEvent } = useAnalytics()
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const pendingSchedulingStages = getPendingSchedulingStages(stages)

  const getSteps = useCallback(
    (firstStep: ScheduleSidebarModeType = 'scheduling') => {
      const res: ScheduleSidebarModeType[] = [firstStep]

      if (firstStep === 'view') {
        return res
      }

      if (values.is_candidate_involved) {
        res.push('email')
      }

      res.push('preview', 'view')

      return res
    },
    [values.is_candidate_involved],
  )

  const firstStep = initialMode || 'scheduling'
  const [steps, setSteps] = useState<ScheduleSidebarModeType[]>(getSteps(initialMode))
  const [mode, setModeState] = useState<ScheduleSidebarModeType>(firstStep)
  const [visitedSteps, setVisitedSteps] = useState([firstStep])

  const setMode = (newMode: ScheduleSidebarModeType) => {
    setModeState(newMode)

    if (visitedSteps[visitedSteps.length - 1] !== newMode) {
      setVisitedSteps(prev => [...prev, newMode])
    }
  }

  const getInitialStage = () => {
    if (isPrepCall) {
      return getSelectedStage(stages, selectedStageId)
    }

    switch (initialMode) {
      case 'scheduling': {
        return getSelectedStage(pendingSchedulingStages, selectedStageId)
      }
      default:
        return getSelectedStage(stages, selectedStageId)
    }
  }

  // @TODO: connect with the setting once the BE ready
  const isCalendarDisabled = false

  const [tab, setTab] = useState(isCalendarDisabled ? Tabs.Manual : Tabs.Automatic)
  const [selectedSlots, setSelectedSlots] = useState<AvailableInterviewerSlot[]>([])
  const [dateFilter, setDateFilter] = useState<OptionInterface | undefined>(
    DateFilterOptions[1],
  )
  const [interviewersFilter, setInterviewersFilter] = useState<OptionInterface[]>()
  const [customSlot, setCustomSlot] = useState<string>()
  const {
    data: interview,
    refetch: refetchInterview,
    isLoading,
  } = useGetSchedulingInterview(
    values.interview_stage?.id,
    roundId,
    isPrepCall,
    interviewId,
  )
  const { data: durationUnits = [], isFetched: unitsFetched } = useGetSelectors<
    IdAndName<DurationUnitType>
  >(selectorKeys.hiring_stage_duration_units)

  useEffect(() => {
    if (!values.scheduling_timezone) {
      values.scheduling_timezone = interview?.scheduling_timezone
    }
  }, [interview?.scheduling_timezone])
  const loadCustomDate = () => {
    if (
      (!values.custom_date?.day || !values.custom_date?.time) &&
      interview?.event_date_time &&
      interview?.scheduling_timezone.id
    ) {
      values.custom_date = dateToCustomDate(
        interview.event_date_time,
        interview?.scheduling_timezone.id,
      )
      values.duration = interview.duration
      values.duration_unit = durationUnits.find(
        option => option.id === interview.duration_unit,
      )
    }
  }
  const [editSuccess, setEditSuccess] = useState<boolean | null>(null)
  const [editLoading, setEditLoading] = useState(false)

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)

  const durationUnitOptions = useMemo(
    () =>
      durationUnits?.map(item => ({
        label: item.name,
        value: item,
      })),
    [durationUnits],
  )

  useEffect(() => {
    setSteps(getSteps(initialMode))
  }, [initialMode, values.is_candidate_involved])

  const moveNextStep = () => {
    const currentIdx = steps.findIndex(item => item === mode)
    setMode(steps[currentIdx + 1] || steps[steps.length - 1])
  }

  const movePrevStep = () => {
    const currentIdx = steps.findIndex(item => item === mode)
    setMode(steps[currentIdx - 1] || steps[0])
  }

  const interviewScheduled = interview?.id

  useEffect(() => {
    changeStage(getInitialStage())
  }, [selectedStageId, stages])

  useEffect(() => {
    setMode(initialMode || 'scheduling')
  }, [initialMode, selectedStageId])

  useEffect(() => {
    if (!unitsFetched) {
      return
    }

    values.duration_unit = durationUnits.find(
      option => option.id === values.interview_stage?.duration_unit,
    )
  }, [unitsFetched])

  useEffect(() => {
    values.interview_stage = getInitialStage()
    values.is_candidate_involved = true
    values.additional_interviewers = []
  }, [])

  const changeStage = (interviewStage?: InterviewStageWithoutRoundInterface) => {
    values.interview_stage = interviewStage
    values.duration = interviewStage?.duration
    values.duration_unit = durationUnits.find(
      option => option.id === interviewStage?.duration_unit,
    )
  }

  const onSchedule = () => {
    if (!isPrepCall) {
      switch (tab) {
        case Tabs.Automatic:
          sendAnalyticsEvent(AnalyticsEvents.schedule_interview_auto)
          break

        case Tabs.Custom:
          sendAnalyticsEvent(AnalyticsEvents.schedule_interview_custom)
          break

        case Tabs.Manual:
          sendAnalyticsEvent(AnalyticsEvents.schedule_interview_manual)
          break
      }
    } else {
      switch (tab) {
        case Tabs.Automatic:
          sendAnalyticsEvent(AnalyticsEvents.schedule_prep_call_auto)
          break

        case Tabs.Custom:
          sendAnalyticsEvent(AnalyticsEvents.schedule_prep_call_custom)
          break

        case Tabs.Manual:
          sendAnalyticsEvent(AnalyticsEvents.schedule_prep_call_manual)
          break
      }
    }
  }

  const schedulingType =
    tab === Tabs.Automatic ? SchedulingType.classic : SchedulingType.custom

  const renderContent = () => {
    if (isLoading) {
      return <Loader />
    }

    if (!roundId || !stages.length || !values.interview_stage) {
      return null
    }

    if (interviewScheduled && mode === 'view') {
      return (
        <ScheduledInterview
          roundId={roundId}
          interview={interview}
          onReschedule={() => {
            onChangeInitialMode?.('rescheduling')
          }}
          onCancelInterview={async () => {
            sendAnalyticsEvent(AnalyticsEvents.cancel_interview)
            await refetchInterview()
            await onRefresh?.()
          }}
          onEdit={() => {
            setMode('editing')
            setTab(Tabs.Manual)
            loadCustomDate()

            if (interview?.interviewer) {
              values.interviewer = { ...interview.interviewer }
            }

            if (interview?.additional_interviewers?.length) {
              values.additional_interviewers = [...interview.additional_interviewers]
            }
          }}
          isPrepCall={isPrepCall}
        />
      )
    }

    if (mode === 'email') {
      return (
        <EmailScheduleSidebar
          onBack={() => {
            movePrevStep()
          }}
          roundId={roundId}
          onSubmit={() => {
            moveNextStep()
          }}
          schedulingType={schedulingType}
          isPrepCall={isPrepCall}
          noInitialFetching={visitedSteps.includes('preview')}
        />
      )
    }

    if (mode === 'preview') {
      return (
        <PreviewScheduleSidebar
          selectedSlots={selectedSlots}
          onBack={() => {
            movePrevStep()
          }}
          onEditDetails={() => {
            setMode(steps[0])
          }}
          onEditEmail={() => {
            setMode('email')
          }}
          roundId={roundId}
          onSubmit={() => {
            moveNextStep()
            onChangeInitialMode?.('view')
            onSchedule()
            refetchInterview()
            onSchedulingSuccess?.(values.interview_stage!)
            onRefresh?.()
          }}
          schedulingType={schedulingType}
          isPrepCall={isPrepCall}
        />
      )
    }

    let isSubmitEnabled = false
    switch (tab) {
      case Tabs.Automatic:
        isSubmitEnabled = !!selectedSlots.length
        break
      case Tabs.Custom:
      case Tabs.Manual:
        isSubmitEnabled = !!(
          !editLoading &&
          values.interviewer &&
          values.custom_date?.day &&
          values.custom_date?.time &&
          !values.custom_date?.timeError
        )
        break
    }

    return (
      <EditScheduleSidebar
        stages={isPrepCall ? stages : pendingSchedulingStages}
        roundId={roundId}
        onSubmit={async () => {
          if (mode === 'editing') {
            if (
              values.interview_stage?.id &&
              roundId &&
              interview?.id &&
              values.custom_date
            ) {
              const eventDateTime = customDateToDate(
                values.custom_date,
                timeZoneId,
              ).toISOString()
              try {
                setEditLoading(true)
                await editInterview(
                  values.interview_stage.id,
                  roundId,
                  interview.id,
                  {
                    interviewer: values.interviewer || undefined,
                    additional_interviewers: values.additional_interviewers,
                    event_date_time: eventDateTime,
                    scheduling_timezone: { id: values.scheduling_timezone!.id },
                    duration: values.duration,
                    duration_unit: values.duration_unit,
                  },
                  apiHandler,
                )
                sendAnalyticsEvent(AnalyticsEvents.edit_scheduled_interview)
                setEditSuccess(true)
              } catch (e) {
                setEditSuccess(false)
              } finally {
                setEditLoading(false)
                refetchInterview()
                onRefresh?.()
                moveNextStep()
              }
            }
          } else {
            moveNextStep()
          }
        }}
        totalStagesCount={totalStagesCount}
        isSubmitEnabled={isSubmitEnabled}
        isRescheduling={mode === 'rescheduling'}
        isEditing={mode === 'editing'}
        isPrepCall={isPrepCall}
        isStageDisabled={isStageDisabled}
        loading={editLoading}
        onStageChange={changeStage}
      >
        <VStack gap="s-16">
          <InputGroup variant="horizontal">
            <LapeNewInput
              name="duration"
              label="Duration"
              width="50%"
              type="number"
              onChange={e => {
                values.duration = +e.currentTarget.value
              }}
              required
            />
            <LapeRadioSelectInput<IdAndName<string>>
              label="Unit"
              labelPath="name"
              name="duration_unit"
              options={durationUnitOptions}
            />
          </InputGroup>
          {mode !== 'editing' && (
            <>
              {isCalendarDisabled ? (
                <Box>
                  <Subheader variant="nested">
                    <Subheader.Title>Event Details</Subheader.Title>
                  </Subheader>
                  <InputGroup>
                    <LapeNewInput label="Event title" name="event_title" />
                    <LapeNewInput
                      label="Meeting link"
                      name="meeting_link"
                      message="You can add a link to Zoom, Teams or other platform where the meeting will be held. A button with a link to the meeting will be added to the email"
                    />
                  </InputGroup>
                </Box>
              ) : (
                <>
                  <CalendarSelect roundId={roundId} />
                  <Box>
                    <Subheader variant="nested">
                      <Subheader.Title>Select preferred slots</Subheader.Title>
                    </Subheader>
                    <TabBar variant="segmented">
                      {[Tabs.Automatic, Tabs.Custom, Tabs.Manual].map(elm => (
                        <TabBar.Item
                          key={elm}
                          aria-selected={tab === elm}
                          onClick={() => {
                            setTab(elm)
                            if (elm === Tabs.Manual) {
                              loadCustomDate()
                            }
                            if (elm === Tabs.Custom) {
                              values.custom_date = {}
                            }
                          }}
                        >
                          <Text use="div" px="s-20">
                            {elm}
                          </Text>
                        </TabBar.Item>
                      ))}
                    </TabBar>
                  </Box>
                </>
              )}
            </>
          )}
          {Tabs.Automatic === tab && (
            <EditSlotsDate
              onChangeSlots={setSelectedSlots}
              roundId={roundId}
              setDateFilter={setDateFilter}
              interviewersFilter={interviewersFilter}
              setInterviewersFilter={setInterviewersFilter}
              selectedSlots={selectedSlots}
              dateFilter={dateFilter}
              onClose={() => setTab(Tabs.Manual)}
              isPrepCall={!!isPrepCall}
            />
          )}
          {Tabs.Custom === tab && (
            <EditCustomDate
              roundId={roundId}
              customSlot={customSlot}
              onChangeCustomSlot={setCustomSlot}
              onClose={() => setTab(Tabs.Manual)}
            />
          )}
          {Tabs.Manual === tab && <EditManualDate mode={mode} />}
        </VStack>
      </EditScheduleSidebar>
    )
  }

  return (
    <>
      {renderContent()}

      {editSuccess !== null && (
        <>
          <StatusPopup
            variant="error"
            open={editSuccess === false}
            onClose={() => setEditSuccess(null)}
            // @ts-ignore
            labelButtonClose="Close error popup"
          >
            <StatusPopup.Title>Something went wrong</StatusPopup.Title>
            <StatusPopup.Description>
              Please refresh the page and try again
            </StatusPopup.Description>
          </StatusPopup>
          <StatusPopup
            variant="success"
            open={editSuccess}
            onClose={() => setEditSuccess(null)}
            // @ts-ignore
            labelButtonClose="Close success popup"
          >
            <StatusPopup.Title>Interview was edited successfully</StatusPopup.Title>
          </StatusPopup>
        </>
      )}
    </>
  )
}

export default (props: Props) => (
  <SideBar useLayout customHeader={<></>} isOpen onClose={props.onClose}>
    <LapeForm onSubmit={() => Promise.resolve({})}>
      <ScheduleSidebar {...props} />
    </LapeForm>
  </SideBar>
)
