import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Avatar, Cell, Item, MoreBar, Subheader, Token, VStack } from '@revolut/ui-kit'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'
import {
  addDocumentSignature,
  addDocumentUpload,
  useDocumentsSignature,
  useDocumentsUpload,
  useTasks,
} from '@src/api/onboardingEmployeesV2'
import {
  OnboardingTaskInterface,
  OnboardingTemplateInterface,
  OnboardingStatus,
} from '@src/interfaces/onboardingV2'
import { SelectTemplatesSidebar } from '@src/apps/People/Documents/BulkRequest/Edit/components/SelectTemplatesSidebar'
import { ScreeningCheck } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/ScreeningCheck'
import { RightToWork } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/RightToWork'
import { PreviewTemplateSidebar } from './PreviewTemplateSidebar'
import { TaskItem } from './TaskItem'
import { TemplateItem } from './TemplateItem'
import { TaskSidebar } from './TaskSidebar'

type Props = {
  status: OnboardingStatus
}

export const OnboardingInformation = ({ status }: Props) => {
  const { id, onboardingId } = useParams<{ id: string; onboardingId: string }>()
  const [task, setTask] = useState<OnboardingTaskInterface>()
  const [taskSideBarOpen, setTaskSiderOpen] = useState(false)
  const [templatesSidebar, setTemplatesSidebar] = useState<'request' | 'esignature'>()
  const [templatePreview, setTemplatePreview] = useState<OnboardingTemplateInterface>()

  const { data: documentUploadData, refetch: refetchDocumentUploadData } =
    useDocumentsUpload(onboardingId)
  const { data: documentSignatureData, refetch: refetchDocumentSignatureData } =
    useDocumentsSignature(onboardingId)
  const { data: tasksData, refetch: refetchTasksData } = useTasks(onboardingId)

  const attachedTemplates =
    (templatesSidebar && templatesSidebar === 'request'
      ? documentUploadData
      : documentSignatureData
    )?.results.map(template => ({
      id: template.document_template.id,
      name: template.document_template.name,
    })) || []

  const handleDocumentUpload = async (templateId: number) => {
    try {
      await addDocumentUpload(onboardingId, templateId)
    } finally {
      refetchDocumentUploadData()
    }
  }
  const handleDocumentSignature = async (templateId: number) => {
    try {
      await addDocumentSignature(onboardingId, templateId)
    } finally {
      refetchDocumentSignatureData()
    }
  }

  const handleAddTemplate = (newTemplate: AttachedTemplateInterface) => {
    templatesSidebar === 'request'
      ? handleDocumentUpload(newTemplate.id)
      : handleDocumentSignature(newTemplate.id)
  }

  return (
    <>
      <VStack gap="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Documents for the employee to upload</Subheader.Title>
        </Subheader>
        <Cell>
          <VStack width="100%" space="s-16">
            {!!documentUploadData?.results.length && (
              <VStack>
                {documentUploadData.results.map(documentUpload => (
                  <TemplateItem
                    key={documentUpload.id}
                    status={status}
                    template={documentUpload}
                    handleClick={() => setTemplatePreview(documentUpload)}
                  />
                ))}
              </VStack>
            )}
            {!documentUploadData?.results.length && (
              <Item>
                <Item.Avatar>
                  <Avatar color={Token.color.greyTone20} useIcon="Document" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title color={Token.color.greyTone50}>
                    Nothing requested yet
                  </Item.Title>
                </Item.Content>
              </Item>
            )}
            <MoreBar>
              <MoreBar.Action
                onClick={() => setTemplatesSidebar('request')}
                useIcon="Plus"
              >
                Request new document
              </MoreBar.Action>
            </MoreBar>
          </VStack>
        </Cell>

        <Subheader variant="nested">
          <Subheader.Title>Documents for the employee to sign</Subheader.Title>
        </Subheader>
        <Cell>
          <VStack width="100%" space="s-16">
            {!!documentSignatureData?.results.length && (
              <VStack>
                {documentSignatureData.results.map(documentSignature => (
                  <TemplateItem
                    key={documentSignature.id}
                    status={status}
                    template={documentSignature}
                    handleClick={() => setTemplatePreview(documentSignature)}
                  />
                ))}
              </VStack>
            )}
            {!documentSignatureData?.results.length && (
              <Item>
                <Item.Avatar>
                  <Avatar color={Token.color.greyTone20} useIcon="Pencil" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title color={Token.color.greyTone50}>
                    Nothing requested yet
                  </Item.Title>
                </Item.Content>
              </Item>
            )}
            <MoreBar>
              <MoreBar.Action
                onClick={() => setTemplatesSidebar('esignature')}
                useIcon="Plus"
              >
                Request new document
              </MoreBar.Action>
            </MoreBar>
          </VStack>
        </Cell>

        <Subheader variant="nested">
          <Subheader.Title>Tasks</Subheader.Title>
        </Subheader>
        <Cell>
          <VStack width="100%" space="s-16">
            {!!tasksData?.results.length && (
              <VStack>
                {tasksData.results.map(taskItem => (
                  <TaskItem
                    key={taskItem.id}
                    task={taskItem}
                    handleClick={() => {
                      setTaskSiderOpen(true)
                      setTask(taskItem)
                    }}
                  />
                ))}
              </VStack>
            )}
            {!tasksData?.results.length && (
              <Item>
                <Item.Avatar>
                  <Avatar color={Token.color.greyTone20} useIcon="RadiobuttonOff" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title color={Token.color.greyTone50}>
                    Nothing added yet
                  </Item.Title>
                </Item.Content>
              </Item>
            )}
            <MoreBar>
              <MoreBar.Action onClick={() => setTaskSiderOpen(true)} useIcon="Plus">
                Add task
              </MoreBar.Action>
            </MoreBar>
          </VStack>
        </Cell>

        <ScreeningCheck employeeId={Number(id)} hideTableRoute />
        <RightToWork employeeId={Number(id)} hideTableRoute />
      </VStack>

      {!!templatesSidebar && (
        <SelectTemplatesSidebar
          allowCreate={templatesSidebar === 'request'}
          attachedTemplates={attachedTemplates}
          filterByTypes={[templatesSidebar]}
          isOpen={!!templatesSidebar}
          onAddTemplate={newTemplate => handleAddTemplate(newTemplate)}
          onClose={() => setTemplatesSidebar(undefined)}
        />
      )}

      <PreviewTemplateSidebar
        document={templatePreview}
        employeeId={id}
        onClose={() => setTemplatePreview(undefined)}
        onboardingId={onboardingId}
      />

      <TaskSidebar
        isOpen={taskSideBarOpen}
        onClose={() => {
          setTaskSiderOpen(false)
          setTask(undefined)
          refetchTasksData()
        }}
        onboardingId={onboardingId}
        refetchTasksData={refetchTasksData}
        task={task}
      />
    </>
  )
}
