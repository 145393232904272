import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { isNil, omit, set } from 'lodash'
import { arrayErrorsToFormError, difference } from '@src/utils/form'
import { Params, RequestInterfaceNew } from '@src/interfaces'
import LapeForm, { LapeFormInterface } from '@src/features/Form/LapeForm'
import { connect, useLape } from 'lape'
import FormLocalstorageLape, {
  FormLocalstorageProps,
} from '@src/features/Form/FormLocalstorageLape'
import { FormValidatorProvider } from '@src/features/Form/FormValidator'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { FormErrorGuard } from './FormErrorGuard'
import PageLoading from '@components/PageLoading/PageLoading'
import { QueryKey, useQueryClient } from 'react-query'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { getLocationPathnameWithoutWorkspace } from '@src/actions/RouterActions'

export interface FormProps<T> {
  validator?: object
  forceParams?: Params
  children: React.ReactNode
  isExistingForm?: boolean
  disableLoading?: boolean
  api?: RequestInterfaceNew<T>
  disableDataCleanup?: boolean
  disableLocalStorageCaching?: boolean
  useLocalStorageCaching?: boolean
  onFetchData?: (data: T, cachedData: Partial<T>) => T
  fieldsToExclude?: string[]
  localStorageProps?: Partial<FormLocalstorageProps>
  /** For LapeMultiInput fields, this will omit the cleared input values before submitting to the API */
  clearMultiInputFields?: string[]
  forceSetIsExistingData?: boolean
  invalidateQueries?: QueryKey[]
  initialValues?: Partial<T>
  loadingState?: ReactNode
  ignoreLocationState?: boolean
  refetchOnLocationChange?: boolean
  useEntityIdFromData?: boolean
}

const Form = <T extends { id?: number | string }>({
  validator,
  api,
  forceParams,
  children,
  isExistingForm,
  disableLoading,
  disableDataCleanup,
  disableLocalStorageCaching = false,
  useLocalStorageCaching = false,
  onFetchData = data => data,
  fieldsToExclude,
  localStorageProps = {},
  clearMultiInputFields,
  forceSetIsExistingData,
  invalidateQueries,
  initialValues,
  loadingState,
  ignoreLocationState = false,
  refetchOnLocationChange = false,
  useEntityIdFromData,
}: FormProps<T>) => {
  const queryClient = useQueryClient()
  const { sendAnalyticsEvent } = useAnalytics()
  const url = getLocationPathnameWithoutWorkspace() + window.location.search
  const params = useParams<{ id: string; new: string }>()
  const actualParams = forceParams || params

  let isExistingDataInitial =
    isExistingForm !== undefined ? isExistingForm : !isNil(actualParams.id)
  if (actualParams?.new || !api) {
    isExistingDataInitial = false
  }
  const [isExistingData, setIsExistingData] = useState(isExistingDataInitial)

  useEffect(() => {
    // isExistingData doesn't go back to false if was once set to true because of this check
    // not quite sure why this check needed here but added forceSetIsExistingData just not to risk removing it
    if (!isExistingData || forceSetIsExistingData) {
      setIsExistingData(isExistingDataInitial)
    }
  }, [isExistingDataInitial])

  const location = useLocation<{ initialValues: any; returnAs: string }>()

  const savedData =
    (!isExistingData || useLocalStorageCaching) && workspaceLocalStorage.getItem(url)
  const parsedSavedData = savedData ? JSON.parse(savedData) : {}
  const initialFormData = useMemo(
    () => ({
      ...(initialValues || {}),
      ...(disableLocalStorageCaching ? {} : parsedSavedData),
      ...((!ignoreLocationState && location?.state?.initialValues) || {}),
    }),
    [initialValues],
  )
  const [formData, setFormData] = useState(initialFormData)
  const state = useLape<{ loading: boolean; error: any }>({
    loading: true,
    error: null,
  })

  useEffect(() => {
    const fetchData = async () => {
      if (api) {
        try {
          state.loading = true
          const result = await api.get(actualParams)
          const data = onFetchData(result.data, parsedSavedData)
          setFormData(data)
        } catch (error) {
          state.error = error
        } finally {
          state.loading = false
        }
      } else {
        state.loading = false
        setFormData({})
      }
    }

    if (isExistingDataInitial) {
      fetchData()
    } else {
      if (formData !== initialFormData) {
        setFormData(initialFormData)
      }
      state.loading = false
    }
  }, [actualParams.id, refetchOnLocationChange && location.pathname])

  const submit = async (form: LapeFormInterface<T>) => {
    if (!api) {
      return Promise.resolve(form.values)
    }

    const startTime = performance.now()

    if (isExistingData) {
      let diff = disableDataCleanup
        ? form.values
        : difference(form.values, form.initialValues)

      if (fieldsToExclude) {
        diff = omit(diff, fieldsToExclude)
      }

      clearMultiInputFields?.forEach(field => {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        if (diff[field]) {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          set(diff, field, transformMultiInput(diff[field]))
        }
      })

      if (useEntityIdFromData && !actualParams.id && form.values.id) {
        actualParams.id = String(form.values.id)
      }

      return api
        .update(diff, actualParams, form.values, form.initialValues)
        .then(({ data }) => {
          form.reset(data)
          const finishTime = performance.now()
          sendAnalyticsEvent(AnalyticsEvents.update_form_success, {
            time: Math.round(finishTime - startTime),
          })
          invalidateQueries &&
            invalidateQueries.forEach(queryKey => queryClient.invalidateQueries(queryKey))

          return data
        })
        .catch(error => {
          const errors = arrayErrorsToFormError<T>(error?.response?.data)
          // FIXME: Left for backwards compatibility. Should be removed eventually
          form.errors = errors
          form.apiErrors = errors
          const finishTime = performance.now()
          sendAnalyticsEvent(AnalyticsEvents.update_form_error, {
            errors: JSON.stringify(errors),
            time: Math.round(finishTime - startTime),
          })
          throw error
        })
    }

    clearMultiInputFields?.forEach(field => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      if (form.values[field]) {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        set(form.values, field, transformMultiInput(form.values[field]))
      }
    })

    return api
      .submit(form.values, actualParams)
      .then(result => {
        workspaceLocalStorage.removeItem(url)
        result.data && form.reset(result.data)
        setIsExistingData(true)
        const finishTime = performance.now()
        sendAnalyticsEvent(AnalyticsEvents.submit_form_success, {
          time: Math.round(finishTime - startTime),
        })
        return result.data
      })
      .catch(error => {
        const errors = arrayErrorsToFormError<T>(error?.response?.data)
        // FIXME: Left for backwards compatibility. Should be removed eventually
        form.errors = errors
        form.apiErrors = errors
        const finishTime = performance.now()
        sendAnalyticsEvent(AnalyticsEvents.submit_form_error, {
          errors: JSON.stringify(errors),
          time: Math.round(finishTime - startTime),
        })
        throw error
      })
  }

  if (state.loading && !disableLoading) {
    return loadingState ? <>{loadingState}</> : <PageLoading />
  }

  return (
    <FormErrorGuard error={state.error}>
      <LapeForm
        onSubmit={submit}
        validation={validator}
        loading={state.loading}
        initialValues={formData}
        fieldsToExclude={fieldsToExclude}
      >
        <FormLocalstorageLape
          isExistingData={useLocalStorageCaching ? false : isExistingData}
          disabled={disableLocalStorageCaching}
          url={url}
          {...localStorageProps}
        />
        <FormValidatorProvider>{children}</FormValidatorProvider>
      </LapeForm>
    </FormErrorGuard>
  )
}

const transformMultiInput = (values?: string[] | null) => {
  if (values == null) {
    return undefined
  }
  return values.filter(Boolean)
}

export default connect(Form)
