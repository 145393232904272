import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { WorkSchedule } from './WorkSchedule'
import { TimeOffPage } from '@src/pages/People/PeopleSubTabs/TimeOffPage/TimeOff'
import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS } from '@src/constants/hub'

export const TimeManagement = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.ANY}>
        <WorkSchedule />
      </Route>

      <Route path={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.ANY}>
        <TimeOffPage />
      </Route>

      <Route path={ROUTES.APPS.TIME_MANAGEMENT.ANY}>
        <HubApp app={APPLICATIONS.timeOff} />
      </Route>
    </Switch>
  )
}
