import { useLapeContext } from '@src/features/Form/LapeForm'
import { useFormObserver } from './Widgets/FormObserverProvider'
import { GoalContentType, GoalsInterface } from '@src/interfaces/goals'
import { useSubmitFlowHelpers } from '../common/utils'
import { goalsRequests } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { isGenericNewGoalPath } from '../helpers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGoalFormCache } from '../useGoalFormCache'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'

export const useGoalFormSubmit = () => {
  const goalForm = useLapeContext<
    GoalsInterface & { targets: unknown; is_submit?: boolean }
  >()
  const { data: contentTypes } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const isGenericNewGoal = isGenericNewGoalPath()
  const { query } = useQuery<{ type: EntityTypes }>()
  const { values, submit } = goalForm
  const { getFormsGetters, unregisterForm } = useFormObserver()
  const { cleanCache } = useGoalFormCache()
  const { showLoading } = useSubmitFlowHelpers()

  // eslint-disable-next-line consistent-return
  const doSubmit = async (updateStatus: boolean) => {
    const targetFormsGetters = getFormsGetters()
    let targetsError: unknown = false

    const hideLoading = showLoading('Saving...')

    if (values.update_type?.id === 'target_based' && targetFormsGetters.length) {
      /*
          we need sequence of requests because BE blocks DB per request 
          error example: 
              deadlock detected
              DETAIL:  Process 2843751 waits for ShareLock on transaction 1046283; blocked by process 2843674.
              Process 2843674 waits for ShareLock on transaction 1046282; blocked by process 2843751.
              HINT:  See server log for query details.
              CONTEXT:  while updating tuple (115,42) in relation "kpi_target"
          */
      for (const getter of targetFormsGetters) {
        const { form, validator } = getter()
        const formId = form.values.id || form.values.tempId

        if (!form.dirty && !form.values.tempId) {
          // eslint-disable-next-line no-continue
          continue
        }

        form.apiErrors = {}
        const cb = validator?.validate
          ? // eslint-disable-next-line no-loop-func
            validator.validate(async () => {
              try {
                // Required while adding goal via performance. At this stage doesn't know what type of goal is it, and it's required before we update the KPIs (the form.submit()) that are required for the form submittion (submit())
                if (
                  isGenericNewGoal &&
                  [EntityTypes.department, EntityTypes.teams].includes(query.type)
                ) {
                  const contentType = contentTypes?.find(
                    ({ model }) => model === query.type,
                  )
                  await goalsRequests.update(
                    {
                      object_id: values.content_object.id,
                      content_object: values.content_object,
                      content_type: contentType,
                    },
                    { id: String(values.id) },
                  )

                  if (query.type === EntityTypes.department) {
                    form.values.department = values.content_object
                  }
                  if (query.type === EntityTypes.teams) {
                    form.values.team = values.content_object
                  }
                }

                const result = await form.submit()
                const goalTarget = {
                  ...result,
                  status: { id: result.status, name: result.status },
                }
                formId && unregisterForm(formId)
                values.kpis = values.kpis.map(kpi =>
                  kpi.tempId === formId ? goalTarget : kpi,
                )
                return goalTarget
              } catch (err) {
                if (err?.response?.data?.object_id) {
                  goalForm.apiErrors.object_id =
                    err?.response?.data?.object_id?.[0] ||
                    'Organisational unit cannot be empty'
                }
                targetsError = err
                throw err
              }
            })
          : form.submit

        // eslint-disable-next-line no-await-in-loop
        await cb()
      }
    }

    if (!targetsError) {
      let statusUpdated = false
      goalForm.apiErrors = {}

      if (values.approval_status.id === ApprovalStatuses.Draft && updateStatus) {
        values.is_submit = true
        statusUpdated = true
      }

      if (!values.name) {
        // only empty string will trigger BE validation error in case of empty
        values.name = ''
      }

      try {
        const result = await submit()
        cleanCache()
        return result
      } catch (err) {
        if (statusUpdated) {
          values.approval_status = {
            id: ApprovalStatuses.Draft,
            name: ApprovalStatuses.Draft,
          }
        }
        throw err
      } finally {
        hideLoading()
      }
    } else {
      hideLoading()
      // eslint-disable-next-line no-throw-literal
      throw targetsError
    }
  }

  return { submit: doSubmit }
}
