import React, { useEffect, useRef } from 'react'
import {
  DeliverableOptions,
  deliverableOptionToFinalGrade,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  DeliverableGradeOption,
  getKpiGrades,
  PerfRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { TableWidget, VStack } from '@revolut/ui-kit'
import { NoPersonalGoalsBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/NoPersonalGoalsBanner'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { getGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { GoalsInterface } from '@src/interfaces/goals'
import { TableNames } from '@src/constants/table'
import {
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsStatusColumn,
  goalsWeightColumn,
  simpleGoalNameColumn,
} from '@src/constants/columns/goals'
import { useTable } from '@src/components/Table/hooks'
import { goalsTableWithoutChildren } from '@src/api/goals'

interface DeliverablesCardInterface extends CommonCardProps {
  setMissingDeliverablesJustification?: React.Dispatch<React.SetStateAction<boolean>>
  showBeforeSubmitCheckError?: boolean
}

export const DeliverablesCard = connect(
  ({
    onHelpClick,
    setMissingDeliverablesJustification,
    showBeforeSubmitCheckError,
    gradesMap,
  }: DeliverablesCardInterface) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasDeliverables = !!values.review_data.deliverables
    const ref = useRef<HTMLDivElement>(null)
    const { grades } = useRecommendedGradesContext()
    const goalsTable = useTable<GoalsInterface>(
      goalsTableWithoutChildren,
      [
        {
          columnName: 'is_company',
          filters: [{ id: 'True', name: 'True' }],
          nonResettable: true,
        },
        values.cycle
          ? {
              columnName: 'cycle__id',
              filters: [{ id: values.cycle.id, name: values.cycle.name || '' }],
              nonResettable: true,
            }
          : undefined,
      ].filter(Boolean),
    )

    useEffect(() => {
      if (errors.review_data?.deliverables || showBeforeSubmitCheckError) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.deliverables, showBeforeSubmitCheckError])

    if (!hasDeliverables) {
      return null
    }

    const deliverablesGrades = getKpiGrades(gradesMap, false)

    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating =
      !isSelfReview && grades?.deliverablesGrade
        ? gradesMap[grades?.deliverablesGrade]
        : undefined

    const gradeRecommendation = DeliverableOptions.INTERMEDIATE
    const gradeValue = values.review_data.deliverables?.cards?.[0]?.rating
    const missingJustification =
      !values.review_data.deliverables?.justifications?.[0]?.comment

    if (gradeRecommendation && gradeValue && missingJustification) {
      const recommendationScore = PerfRatingToGraphNumber[gradeRecommendation]
      const valueScore = PerfRatingToGraphNumber[gradeValue]
      setMissingDeliverablesJustification?.(valueScore > recommendationScore)
    } else {
      setMissingDeliverablesJustification?.(false)
    }

    const deliverablesGradesWithExp = getGradesWithExpectations(
      deliverablesGrades,
      gradeRecommendation,
    )

    const onSelectGrade = (
      reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
      grade: DeliverableGradeOption,
    ) => {
      reviewData.deliverables?.cards?.forEach(card => {
        set(card, `sections.0.value`, grade.key)
        set(card, `rating`, grade.key)
      })
      set(
        reviewData,
        'deliverables.section_grade',
        deliverableOptionToFinalGrade(grade.key),
      )
    }

    return (
      <Card
        data={values}
        renderExpandedContent={() => (
          <Deliverables
            reviewData={values.review_data}
            deliverablesGrades={deliverablesGradesWithExp}
            onSelectGrade={onSelectGrade}
            gradesMap={gradesMap}
          />
        )}
        renderExceedingContent={() => (
          <Deliverables
            reviewData={values.review_data}
            deliverablesGrades={deliverablesGradesWithExp}
            showJustificationError={showBeforeSubmitCheckError && missingJustification}
            justificationOnly
            gradesMap={gradesMap}
          />
        )}
        additionalInfo={
          <VStack space="s-16">
            <NoPersonalGoalsBanner team={values.team} />
            <TableWidget style={{ padding: 0 }}>
              <TableWidget.Table>
                <AdjustableTable<GoalsInterface>
                  name={TableNames.Goals}
                  dataType="Goal"
                  row={{
                    cells: [
                      { ...simpleGoalNameColumn, width: 100 },
                      { ...goalsWeightColumn, width: 50 },
                      { ...goalsOwnerColumn, width: 50 },
                      { ...goalsStatusColumn, width: 50 },
                      { ...goalsProgressColumn, width: 50 },
                    ],
                  }}
                  {...goalsTable}
                  noDataMessage="No Goals defined"
                  noReset
                  hideCountAndButtonSection
                />
              </TableWidget.Table>
            </TableWidget>
          </VStack>
        }
        type={CardContentTypes.DELIVERABLES}
        title="Goals"
        finalRating={finalRating}
        icon="Target"
        fields={[
          {
            field: `review_data.deliverables.cards.0`,
            title: 'Contribution and impact',
            grades: deliverablesGradesWithExp,
            gradeRecommendation,
          },
        ]}
        onSelectDeliverableGrade={grade => onSelectGrade(values.review_data, grade)}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.sections?.[0]?.value
          return !!ratingValue && ratingValue === grade.key
        }}
        justification={values?.review_data?.deliverables?.skipped_section_justification}
        headerRef={ref}
        onHelpClick={onHelpClick ? () => onHelpClick(HelpTabs.Deliverables) : undefined}
      />
    )
  },
)
