import React from 'react'
import { HStack, Skeleton, Tag, Text, Token, Icon } from '@revolut/ui-kit'
import { OnboardingInterface } from '@src/interfaces/onboardingV2'
import { formatDateTime } from '@src/utils/format'
import { getStatusColor } from '../utils'

type OnboardingSubtitleProps = {
  loading: boolean
  onboardingData: OnboardingInterface
}

export const OnboardingSubtitle = ({
  loading,
  onboardingData,
}: OnboardingSubtitleProps) => {
  return (
    <HStack align="center" gap="s-16">
      {loading ? (
        <>
          <Skeleton width={150} /> <Skeleton width={250} />
        </>
      ) : (
        <>
          <Tag color={getStatusColor(onboardingData.status.id)} variant="outlined">
            {onboardingData.status.name}
          </Tag>
          <HStack align="center" gap="s-4">
            <Icon color={Token.color.greyTone50} name="Time" size={16} />
            <Text use="div" color={Token.color.greyTone50} variant="caption">
              {`Last activity at ${formatDateTime(onboardingData.update_date_time)}`}
            </Text>
          </HStack>
        </>
      )}
    </HStack>
  )
}
