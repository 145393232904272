import React from 'react'
import { getOnboardingTimelineCommentsAPI } from '@src/api/onboardingEmployees'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { PageBody } from '@components/Page/PageBody'

type Props = {
  employeeId: string
  onboardingId: string
  onClickSeeAll?: () => void
  refreshStats?: () => void
}

export const Comments = ({
  employeeId,
  onboardingId,
  onClickSeeAll,
  refreshStats,
}: Props) => {
  const commentsApi = getOnboardingTimelineCommentsAPI(employeeId, onboardingId)
  return (
    <PageBody>
      <CommentsSection
        api={commentsApi}
        onRefetch={refreshStats}
        onSideOpen={onClickSeeAll}
      />
    </PageBody>
  )
}
